const apiConfig = require("../config/apiConfig.json");

export const CountryService = {
  getAllCountries: {
    method: "GET",
    url: apiConfig.country.countryEndPoint
  },
  deleteCountry: {
    method: "DELETE",
    url: apiConfig.country.countryEndPoint
  },
  addCountry: {
    method: "POST",
    url: apiConfig.country.countryEndPoint
  },
  editCountry: {
    method: "PUT",
    url: apiConfig.country.countryEndPoint
  },
};
