/**
    * Project          : Pathshala
    * Module           : Layout File
    * Source filename  : Layout.js
    * Description      : describe Layout of the project
    * Author           : Debabrata Pal 
    * Copyright        : Copyright © 2021, Learning Possibilities Limited
    *                    Written under contract by Robosoft Technologies Pvt. Ltd.
    */
   
   

import React from "react";
import { LeftMenu, PageHeader } from "../../component";
import "./Layout.scss";

export default function Layout(props) {
  return (
    <div className="Layout">
      <LeftMenu />
      <main className="Content">
        <PageHeader pageName={props.pageName} />
        <div className="PageBody">{props.children}</div>
      </main>
    </div>
  );
}
