import { useContext, 
    useEffect, 
    useState  
} from 'react';
import { PublishService } from '../../../service';
import { Strings } from '../../../config';
import { NetworkContext } from '../../../config/network/network';
import { SelectBox } from '../../../component';
import { StoreContext } from '../../../config/store/store';

const Publish = ({ countryId, location }) => {

    const [options, setOptions] = useState([]);
    const [publishValue, selectedValue] = useState(0);
    const { networkService } = useContext(NetworkContext);
    const { dispatch, store : { refreshPublish} } = useContext(StoreContext)

    const getPublishedList = () => {
        const { url, method } = PublishService.getAllPublished
        networkService({
          method,
          url: `${url}/${countryId}`
        }).then(response => {
          if (response.status === Strings.success) {
            const data = normalizePublish(response)
            setOptions(data);
            if(data?.length > 0) {
              selectedValue(data[0]?.value)
            }
          }
        });
      };
    
       useEffect(() => {
        getPublishedList();
        // eslint-disable-next-line
      }, [countryId, location]);

      useEffect(() => {
        if(refreshPublish) {
          getPublishedList();
        }
        // eslint-disable-next-line
      }, [refreshPublish]);

      // const normalizePublish = ({ data }) => {
      //   return data
      // }

      const normalizePublish = ({ data }) => {
        let publishList = data.map((country, index) => {
          if(country === 0) {
            return {
              name: 'Edit',
              value: country
            }
          } else {
            return {
              name: country,
              value: country
            }     
          }
        })

        if(!data?.includes(0)) {
          publishList.unshift({
            name: 'Edit',
            value: 0
          });
        }

        return publishList;
      }

      const handlePublishChange = e =>  {
        console.log(e)
        const publishId = e.target.value;
        selectedValue(publishId);
        dispatch({ type: 'PUBLISHID', publishId: publishId})
      }

    return(
        <>
            { options?.length > 0 && <SelectBox
                    name="duration"
                    placeholder={Strings.selectPublishId}
                    width={'200px'}
                    label={Strings.selectPublishId}
                    onChange={handlePublishChange}
                    value={publishValue ? publishValue: 0}
                    options={options}
                    nameKey="name"
                    valueKey="value"
                    required
                    style={{ backgroundColor: "rgb(232, 241, 250)" }}
                />
            }
        
        </>
    )
}

export default Publish