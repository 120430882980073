/**
 * Project          : Pathshala
 * Module           : Root File
 * Source filename  : Root.js
 * Description      : describe Root of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React, { useContext, createContext } from "react";
import { Loader, ErrorModal } from "./component";
import { StoreContext } from "./config/store/store";
import * as actions from "./config/store/actions";
import { Constants, Strings } from "./config";
import * as Utils from "./helper/Utils";
import { Snackbar, Button, makeStyles } from "@material-ui/core";
import axios from "axios";

const MasterLayerContext = createContext({});
const useStyle = makeStyles(() => ({
  rootSnakebar: {
    zIndex: 1600
  }
}));

const MasterLayerProvider = ({ children }) => {
  const { store, dispatch } = useContext(StoreContext);
  const MasterLayerFunctions = {
    showAlertMessage: errorMsg => {
      dispatch(actions.showErrorModal(errorMsg));
      document.addEventListener(
        "keydown",
        MasterLayerFunctions.escAlertMessage
      );
    },
    hideAlertMessage: () => {
      dispatch(actions.hideErrorModal());
      document.removeEventListener(
        "keydown",
        MasterLayerFunctions.escAlertMessage
      );
    },
    escAlertMessage: event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        MasterLayerFunctions.hideAlertMessage();
      }
    }
  };

  const restoreToken = () => {
    axios
      .post(`${Utils.getBaseUrl()}/cms/cms-refresh-token`, {
        token: Utils.getUserDetails().token
      })
      .then(response => {
        if (response.data.status === Strings.success) {
          if (response.data.token) {
            clearTimeout(localStorage.getItem("timerId2"));
            localStorage.removeItem("timerId2");
            Utils.setUserDetails({
              ...Utils.getUserDetails(),
              token: response.data.token
            });
            dispatch(actions.hideSnackBar());
            const timer = setTimeout(() => {
              dispatch(actions.setTimer());
              const timer2 = setTimeout(() => {
                dispatch(actions.hideSnackBar());
              }, Constants.timerToHideSnackBar);
              localStorage.setItem("timerId2", timer2);
            }, Constants.timerToShowSnackBar);
            localStorage.setItem("timerId", timer);
          }
        }
      });
  };

  const errorModelCloseHandler = () => {
    if (store.errorMsg.status === Constants.errorStatus.unauthorized) {
      Utils.clearStorage();
      window.location.reload();
    }
    MasterLayerFunctions.hideAlertMessage();
  };

  const classes = useStyle();
  return (
    <MasterLayerContext.Provider value={{ MasterLayerFunctions }}>
      {children}
      <Loader id="mainLoader" className="loading" />
      <Loader id="mainLoader2" className="loading" />
      <ErrorModal
        open={store.errorMsg}
        errorMsg={store.errorMsg || ""}
        onClose={errorModelCloseHandler}
      />
      <Snackbar
        classes={{ root: classes.rootSnakebar }}
        open={store.showRestoreTokenPopup}
        action={
          <Button color="secondary" onClick={restoreToken} size="small">
            {Strings.restoreToken}
          </Button>
        }
        message={Strings.YourSessonIsAboutToEnd}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      />
    </MasterLayerContext.Provider>
  );
};

export { MasterLayerContext, MasterLayerProvider };
