/**
 * Project          : Pathshala
 * Module           : Button Component file
 * Source filename  : Button.js
 * Description      : the file defines the Button component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
      width: "100%"
    }
  }
}));

// used in login page
export function CustomButton(props) {
  const classes = useStyles();
  const initProps = {
    color: props.color ? props.color : "primary",
    variant: props.variant ? props.variant : "contained",
    ...props
  };
  return (
    <div className={classes.root}>
      <Button {...initProps}>{props.children}</Button>
    </div>
  );
}
