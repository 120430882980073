/**
 * Project          : Pathshala
 * Module           : ToastBar Component file
 * Source filename  : ToastBar.js
 * Description      : the file defines the ToastBar component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited

import React, { useContext, useEffect } from "react";
import { Snackbar, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { StoreContext } from "../../config/store/store";
import * as actions from "../../config/store/actions";
import { Strings } from "../../config";

export function ToastBar() {
  const { store, dispatch } = useContext(StoreContext);
  const useStyles = makeStyles(theme => ({
    root: {
      width: "1000px",
      "& > * + *": {
        marginTop: theme.spacing(2),
        width: "100%"
      }
    }
  }));

  const action = (
    <Button
      color="secondary"
      size="small"
      onClick={() => dispatch(actions.hideToastMessage())}
    >
      {Strings.close}
    </Button>
  );
  const classes = useStyles();

  useEffect(() => {
    let interval = setTimeout(() => {
      dispatch(actions.hideToastMessage());
    }, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [store.toastMsg]);

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(store.toastMsg)}
        onClose={() => dispatch(actions.hideToastMessage())}
        message={store.toastMsg}
        action={action}
      />
    </div>
  );
}
