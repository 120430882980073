/**
 * Project          : Pathshala
 * Module           : App File
 * Source filename  : App.js
 * Description      : describe App of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import { MasterLayerProvider } from "./Root";
import { StoreProvider } from "./config/store/store";
import "./App.scss";
import { Routes } from "./routers/Routes";
import { NetworkProvider } from "./config/network/network";
import { ToastBar } from "./component/ToastBar/ToastBar";
import { Detector } from "react-detect-offline";

export default function App() {
  return (
    <Detector
      render={({ online }) => (
        <StoreProvider>
          <MasterLayerProvider>
            <NetworkProvider online={online}>
              <ToastBar />
              <Routes />
            </NetworkProvider>
          </MasterLayerProvider>
        </StoreProvider>
      )}
    />
  );
}
