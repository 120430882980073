/**
    * Project          : Pathshala
    * Module           : index File
    * Source filename  : index.js
    * Description      : describe index of the project
    * Author           : Debabrata Pal 
    * Copyright        : Copyright © 2021, Learning Possibilities Limited
    *                    Written under contract by Robosoft Technologies Pvt. Ltd.
    */
   
   

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
