/**
 * Project          : Pathshala
 * Module           : BoxInput Component file
 * Source filename  : BoxInput.js
 * Description      : the file defines the BoxInput component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React, { useEffect } from "react";
import "./BoxInput.scss";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as Utils from "../../helper/Utils";
import { Strings } from "../../config";

export function BoxInput(props) {
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");
  const { type } = props;

  const validateInput = e => {
    let isValid = true;
    if (props.required && e.target.value === "") {
      isValid = false;
      setError(true);
      setHelperText(Strings.thisFieldIsRequired);
    } else {
      setError(false);
      setHelperText("");
    }

    if (type === "email") {
      isValid = isValid && Utils.validateEmail(e.target.value);
      if (!isValid) {
        setError(true);
        setHelperText(
          e.target.value
            ? Strings.pleaseEnterValidEmail
            : Strings.thisFieldIsRequired
        );
      } else {
        setError(false);
        setHelperText("");
      }
    }
    return isValid;
  };
  useEffect(() => {
    if (props.error && props.helperText) {
      setHelperText(props.helperText);
      setError(true);
    } else {
      setHelperText("");
      setError(false);
    }
    // eslint-disable-next-line
  }, [props.error]);

  // If input box is disabled for some action error message should not be visible
  useEffect(() => {
    if (props.disabled) {
      if (error) {
        setError(false);
      }
      if (helperText) {
        setHelperText("");
      }
    }

    // eslint-disable-next-line
  }, [props.disabled]);

  const useStyles = makeStyles(theme => ({
    root: {
      width: props.containerWidth || "100%",
      "& .MuiTextField-root": {
        margin: theme.spacing(1.5),
        width: props.width || "unset",
        marginLeft: props.margin || theme.spacing(1.5)
      }
    }
  }));

  // handle on blur
  const onBlur = e => {
    validateInput(e);
  };

  const handleChange = e => {
    let isValid = true;
    isValid = validateInput(e);
    props.onChange({
      value: e.target.value,
      name: e.target.name,
      isValid
    });
  };

  const classes = useStyles();
  let initProps = {
    ...props,

    onChange: handleChange,
    onBlur: onBlur,
    error: props.error || error,
    helperText
  };

  return (
    <div className={classes.root}>
      <TextField {...initProps} />
    </div>
  );
}
