/**
 * Project          : Pathshala
 * Module           : Table Component file
 * Source filename  : Table.js
 * Description      : the file defines the Table component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */
import React from "react";
import MaterialTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import { Row } from "../index";
import {
  makeStyles,
  Paper,
  TableContainer,
  TablePagination
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Strings } from "../../config";
import {
  getIslandStoryRow,
  getOverviewRow,
  getStoryStatusRow,
  getUserTableRow,
  getWelcomeAvatarRow,
  getActivityRow,
  getCountriesRow,
} from "./RowFomatter";

export const Table = props => {
  const useStyles = makeStyles({
    root: {
      width: props.customWidth || "100%"
    },
    container: {
      maxHeight: props.maxHeight || "calc(100vh - 150px)",
      minHeight: props.minHeight || "unset"
    }
  });

  const getTableBodyCellsAndUniqueKey = (screen, row) => {
    let rowObject = {};
    switch (screen) {
      case Strings.userDetails:
        rowObject = getUserTableRow(row, props);
        break;
      case Strings.islandStory:
        rowObject = getIslandStoryRow(row, props);
        break;
      case Strings.activity:
        rowObject = getActivityRow(row, props);
        break;
      case Strings.starterAvatars:
        rowObject = getWelcomeAvatarRow(row, props);
        break;
      case Strings.overview:
        rowObject = getOverviewRow(row, props);
        break;
      case Strings.storyStatus:
        rowObject = getStoryStatusRow(row, props);
        break;
      case Strings.countries:
          rowObject = getCountriesRow(row, props);
          break;
      default:
        rowObject = {};
    }
    return rowObject;
  };

  const renderTableHeader = cells => {
    return (
      <TableHead className={"TableHead"}>
        <Row cells={cells} />
      </TableHead>
    );
  };

  const onCellClickedHandler = (key, item) => {
    if (props.onCellClicked) {
      props.onCellClicked(key, item);
    }
  };

  const renderTableBodyWithDnd = (data, screen) => {
    return (
      <DragDropContext onDragEnd={props.handleDropEnd}>
        <Droppable droppableId={"tablednd"}>
          {provided => (
            <TableBody
              className={"TableBody"}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {data.map((row, index) => {
                const { uniqueKey, cells } = getTableBodyCellsAndUniqueKey(
                  screen,
                  row
                );
                return (
                  <Draggable
                    key={uniqueKey}
                    draggableId={uniqueKey}
                    index={index}
                  >
                    {provide => (
                      <Row
                        dnd={true}
                        dragHandleProps={{ ...provide.dragHandleProps }}
                        draggableProps={{ ...provide.draggableProps }}
                        reference={provide.innerRef}
                        cells={cells}
                        onClick={onCellClickedHandler}
                        item={row}
                      />
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const renderTableBody = (data, screen) => {
    return (
      <TableBody className={"TableBody"}>
        {data.map((row, index) => {
          const { uniqueKey, cells } = getTableBodyCellsAndUniqueKey(
            screen,
            row
          );
          return (
            <Row
              key={`${uniqueKey}_${index}`}
              cells={cells}
              onClick={onCellClickedHandler}
              item={row}
              dnd={false}
            />
          );
        })}
      </TableBody>
    );
  };
  const { headerItems, data, screen, id } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MaterialTable className="Table" stickyHeader id={id}>
          {renderTableHeader(headerItems)}
          {props.dnd
            ? renderTableBodyWithDnd(data, screen)
            : renderTableBody(data, screen)}
        </MaterialTable>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          onChangePage={props.handleChangePage}
          component="div"
          count={props.count}
          rowsPerPage={props.limit}
          page={props.page}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};
