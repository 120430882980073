import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    withStyles
  } from "@material-ui/core";
  import { Add, Close } from "@material-ui/icons";
  import React from "react";
  import {
    BoxInput,
    Table,
    NoDataFound,
    ConfirmationDialog,
    SelectBox,
  } from "../../../component";
  import { Constants, Strings } from "../../../config";
  import { green, purple } from "@material-ui/core/colors";
  import * as Utils from "../../../helper/Utils";
  import "./CountriesDialog.scss";
  
  
  const inputWidth = "350px";
  
  const ColorButton = withStyles(theme => ({
    root: {
      color: "#ffffff",
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: purple[700]
      }
    }
  }))(Button);
  
  export const checkDisable = ({
    formData,
  }) => {
    const isValid = Utils.objectValidator(formData);
    return !isValid;
  };
  
  export const defaultCountry = {
    name: "",
    code: []
  };
  
  export const getParamsToFetch = (searchField) => {
    return {
      searchField: searchField !== "" ? searchField : null
    };
  };
  
  export const renderConfirmationDialog = config => (
    <ConfirmationDialog 
      open={false}
      {...config} 
    />
  );
  
  export const renderConfirmation = (
    path,
    selectedForDelete,
    showDeleteDialog,
    handleDeleteDialogCloseSingle,
    handleDeleteConfirmSingle,
    handleDeleteDialogClose,
    handleDeleteConfirm
  ) => {
    let config = {};
    if (!!selectedForDelete) {
      config.open = true;
      config.handleClose = handleDeleteDialogCloseSingle;
      config.handleConfirm = handleDeleteConfirmSingle;
      config.content = Utils.formatString(
        Strings.areYouSureWantToDeleteSelectedCountry,
        selectedForDelete?.countryName ? selectedForDelete.countryName : ""
      );
    } else if (showDeleteDialog) {
      config.open = true;
      config.handleClose = handleDeleteDialogClose;
      config.handleConfirm = handleDeleteConfirm;
      config.content = Utils.formatString(
        Strings.areYouSureWantToDeleteSelectedCountry,
        `${Strings.countries.toLowerCase()}`
      );
    } 
    return renderConfirmationDialog(config);
  };
  
  export const renderTable = (
    path,
    data,
    handleSelect,
    selectedMultipleForDelete,
    handleEditClick,
    handleDeleteClick
  ) => {
    let headers = Constants.countriesheader;
    return data?.length > 0 ? (
      <Table
        headerItems={headers}
        data={data}
        path={path}
        screen={Strings.countries}
        handleSelect={handleSelect}
        selectedRow={selectedMultipleForDelete}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
      />
    ) : (
      <NoDataFound />
    );
  };
  
  export const renderFilterComponent = ({
    handleAddCountry
  }) => {
    return (
      <div className="Filters">
        <div className="LeftSide">
          <ColorButton
            color="primary"
            variant="contained"
            endIcon={<Add />}
            className="Button"
            size="small"
            onClick={handleAddCountry}
          >
            {Strings.addCountry}
          </ColorButton>
        </div>
      </div>
    );
  };
  
  export const renderCountryForm = ({
    formData,
    handleTextFiledChange,
    countryList,
  }) => {      
    return (
      <>
        <div style={{"margin":"12px"}}>
        {countryList && (<SelectBox
            name="name"
            margin="0"
            width={inputWidth}
            label={Strings.selectCountry}
            options={countryList}
            nameKey="countryName"
            valueKey="iso"
            value={formData.code || ""}
            onChange={handleTextFiledChange}        
          />)}
        </div>
        {/* <BoxInput
          width={inputWidth}
          name="name"
          value={formData.name}
          onChange={handleTextFiledChange}
          label={Strings.countryName}
          required
          variant="standard"
          inputProps={{
            maxLength: 30
          }}
        /> */}
        <BoxInput
          width={inputWidth}
          name="code"
          value={formData.code}
          // onChange={handleTextFiledChange}
          label={Strings.countryCode}
          variant="standard"
          inputProps={{
            maxLength: 16
          }}
          disabled="true"
        />  
      </>
    );
  };
  
  export const renderCountryDialogHeader = (getHeader, onClose) => (
    <div className="TitleContainer">
      <div className="Title">{getHeader()}</div>
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );
  
  export const renderCountryDialogAction = (
    handleDisable,
    parentFormData,
    handleSubmit,
    getHeader
  ) => (
    <Button
      disabled={handleDisable()}
      onClick={() => handleSubmit(!!parentFormData)}
      color="primary"
      variant="contained"
    >
      {getHeader()}
    </Button>
  );
  
  export const handleCaptureData = ({
    formData,
  }) => {
    return {
      countryName: formData.name,
      countryCodeName: formData.code
    }
  };
  
  export const getHeader = ({ parentFormData }) => {
    let header = "";
    if (parentFormData) {
      header = Strings.editCountry;
    } else { 
      header = Strings.addCountry;
    }

    return header;
  };
  
  export const CountryAddEditDialog = ({
    open,
    onClose,
    title,
    content,
    action
  }) => {
    return (
      <Dialog
        className="AddCountryDialog"
        open={open}
        onClose={onClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{action}</DialogActions>
      </Dialog>
    );
  };
  