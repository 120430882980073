/**
 * Project          : Pathshala
 * Module           : Routes File
 * Source filename  : Routes.js
 * Description      : describe Routes of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React, { Suspense, lazy } from "react";
import { Loader } from "../component";
import { Switch, BrowserRouter, Redirect, Route } from "react-router-dom";
import { Strings, urlList } from "../config";
import AuthenticatedRoute from "./AthenticatedRoute";
import * as Utils from "../helper/Utils";

const LoginComponent = lazy(() => import("../container/Login/Login"));
const UserDetailsComponent = lazy(() =>
  import("../container/UserSettings/UserSettings")
);
const IslandStoryComponent = lazy(() =>
  import("../container/IslandStory/IslandStory")
);
const ActivityComponent = lazy(() => import("../container/Activity/Activity"));
const OverviewComponent = lazy(() => import("../container/Overview/Overview"));
const DescriptionComponent = lazy(() =>
  import("../container/Description/Description")
);
const SevenDayTrialComponent = lazy(() =>
  import("../container/SevenDaysTrial/SevenDaysTrial")
);
const StandardMonthlyComponent = lazy(() =>
  import("../container/StandardSubscriptions/StandardSubscription")
);
const StandardAnnualComponent = lazy(() =>
  import("../container/StandardSubscriptions/StandardSubscription")
);
const PremiumComponent = lazy(() =>
  import("../container/StandardSubscriptions/StandardSubscription")
);
const NotificationComponent = lazy(() =>
  import("../container/Notification/Notification")
);
const WelcomeComponent = lazy(() => 
  import("../container/Avatar/Avatar")
);
const Countries = lazy(() =>
  import("../container/Countries/Countries")
);

export const Routes = ({ childProps }) => {
  return (
    <BrowserRouter basename={urlList.base}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path={urlList.login} component={LoginComponent} />
          <AuthenticatedRoute
            path={urlList.islandStory}
            component={IslandStoryComponent}
            name={Strings.islandStory}
          />
          <AuthenticatedRoute
            path={urlList.rewardStory}
            component={IslandStoryComponent}
            name={Strings.rewardStory}
          />
          <AuthenticatedRoute
            path={urlList.activity}
            component={ActivityComponent}
            name={Strings.activity}
          />
          <AuthenticatedRoute
            path={urlList.starterAvatar}
            component={WelcomeComponent}
            name={Strings.starterAvatars}
          />
          <AuthenticatedRoute
            path={urlList.subscriptionAvatars}
            component={WelcomeComponent}
            name={Strings.subscriptionAvatars}
          />
          <AuthenticatedRoute
            path={urlList.landAvatars}
            component={WelcomeComponent}
            name={Strings.landAvatars}
          />
          <AuthenticatedRoute
            path={urlList.buddy}
            component={WelcomeComponent}
            name={Strings.buddy}
          />
          <AuthenticatedRoute
            path={urlList.overview}
            component={OverviewComponent}
            name={Strings.overview}
          />
          {/* <AuthenticatedRoute
            path={urlList.versioning}
            component={VersioningComponent}
            name={Strings.versioning}
          /> */}
          <AuthenticatedRoute
            path={urlList.description}
            component={DescriptionComponent}
            name={Strings.description}
          />
          <AuthenticatedRoute
            path={urlList.sevenDayTrial}
            component={SevenDayTrialComponent}
            name={Strings.sevenDayTrial}
          />
          <AuthenticatedRoute
            path={urlList.standardMonthly}
            component={StandardMonthlyComponent}
            name={Strings.standardMonthly}
          />
          <AuthenticatedRoute
            path={urlList.standardAnnual}
            component={StandardAnnualComponent}
            name={Strings.standardAnnual}
          />
          <AuthenticatedRoute
            path={urlList.premium}
            component={PremiumComponent}
            name={Strings.premium}
          />
          <AuthenticatedRoute
            path={urlList.userDetails}
            component={UserDetailsComponent}
            name={Strings.userDetails}
            permissions={{ rootUser: true }}
          />
          <AuthenticatedRoute
            path={urlList.notification}
            component={NotificationComponent}
            name={Strings.notification}
          />
          <AuthenticatedRoute
            path={urlList.countries}
            component={Countries}
            name={Strings.countries}
          />
          <Redirect to={Utils.permisableRoute()} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
