/**
    * Project          : Pathshala
    * Module           : reportWebVitals File
    * Source filename  : reportWebVitals.js
    * Description      : describe reportWebVitals of the project
    * Author           : Debabrata Pal 
    * Copyright        : Copyright © 2021, Learning Possibilities Limited
    *                    Written under contract by Robosoft Technologies Pvt. Ltd.
    */
   
   

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
