import React from "react";
import { Constants, Strings, urlList } from "../../config";
import { AdminListing } from "./AdminListing/AdminListing";
import { Check, Close, CloudUpload, Memory } from "@material-ui/icons";
import { Button, Checkbox, IconButton, Tooltip } from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const styles = {
  flexDisplay: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    color: "green"
  }
};

const defaultCountry = "PH"

export const getEditDeleteButton = (row, props, isCountries = false) => (
  <>
    { !isCountries && 
      <Button
        className="Button"
        variant="contained"
        color="primary"
        onClick={() => props.handleEditClick(row)}
        size="small"
      >
        {Strings.edit}
      </Button>
    }
    <Button
      className="Button"
      variant="contained"
      size="small"
      color="secondary"
      onClick={() => props.handleDeleteClick(row)}
      disabled={isCountries && row?.countryCodeName === defaultCountry}
    >
      {Strings.delete}
    </Button>
  </>
);

export const getUserTableRow = (row, props) => {
  const cells = [
    {
      value: (
        <AdminListing
          handleSave={props.handleSave}
          name="name"
          value={row.name}
          id={row._id}
        />
      )
    },
    {
      value: (
        <AdminListing
          handleSave={props.handleSave}
          name="email"
          value={row.email}
          id={row._id}
        />
      )
    },
    {
      value: (
        <AdminListing
          handleSave={props.handleSave}
          name="password"
          value={row.password}
          id={row._id}
        />
      )
    }
  ];
  return {
    cells: cells,
    uniqueKey: row.email
  };
};

export const getIslandStoryRow = (row, props) => {
  const checked = props.selectedRow.includes(row._id);
  const StorySymbol = () => {
    let symbol = null;

    switch (row.videoStatus) {
      case 0:
        symbol = <RotateLeftIcon />;
        break;
      case 1:
        symbol = <CloudUpload />;
        break;
      case 2:
        symbol = <Memory />;
        break;
      case 3:
        symbol = <Check />;
        break;
      case 4:
        symbol = <ErrorOutlineIcon />;
        break;
      default:
        symbol = <RotateLeftIcon />;
    }

    return (
      <Tooltip
        title={
          typeof row.videoStatus === "number"
            ? Constants.videoStatus[row.videoStatus].substr(7)
            : ""
        }
      >
        {symbol}
      </Tooltip>
    );
  };
  let cells = [
    {
      value: (
        <Checkbox
          color="primary"
          onClick={() => props.handleSelect(row)}
          checked={checked}
        />
      )
    },
    {
      value: row.sequenceId
    },
    {
      value: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.storyId} <StorySymbol />
        </div>
      )
    },
    {
      value: (
        <img
          style={{
            width: `${Constants.imageRatio.islandStory[0] / 15}px`,
            height: `${Constants.imageRatio.islandStory[1] / 15}px`
          }}
          src={row.coverImageUrl}
          alt={"thumbnail"}
        />
      )
    },
    {
      value: row.title,
      className: "LineBreak"
    },
    {
      value: row.size
    },
    {
      value: row.duration
    },
    {
      value: (
        <div onClick={() => props.onViewSynopsis(row)}>
          {Strings.viewSynopsis}
        </div>
      ),
      className: "Anchor"
    },
    {
      value: <>{!checked && getEditDeleteButton(row, props)}</>
    }
  ];
  if (props.path === urlList.rewardStory) {
    cells.splice(4, 0, {
      value: row.milestone
    });
  }
  return {
    cells,
    uniqueKey: row._id
  };
};

export const getActivityRow = (row, props) => {
  const cells = [
    {
      value: row.storyId
    },
    {
      value: row.title
    },
    {
      value: (
        <div style={styles.flexDisplay}>
          <div
            onClick={() => props.onRoundClick(Constants.roundMap.round1, row)}
          >
            {Strings.round1}
          </div>
          {row.round1QCount > 0 && <Check style={styles.icon} />}
        </div>
      ),
      className: "Anchor"
    },
    {
      value: (
        <div style={styles.flexDisplay}>
          <div
            onClick={() => props.onRoundClick(Constants.roundMap.round2, row)}
          >
            {Strings.round2}
          </div>
          {row.round2QCount > 0 && <Check style={styles.icon} />}
        </div>
      ),
      className: "Anchor"
    },
    {
      value: (
        <div style={styles.flexDisplay}>
          <div
            onClick={() => props.onRoundClick(Constants.roundMap.round3, row)}
          >
            {Strings.round3}
          </div>
          {row.round3QCount > 0 && <Check style={styles.icon} />}
        </div>
      ),
      className: "Anchor"
    },
    {
      value: (() => {
        let round1Qcount = row.round1QCount || 0;
        let round2Qcount = row.round2QCount || 0;
        let round3Qcount = row.round3QCount || 0;
        return (
          <Button
            className="Button"
            variant="contained"
            size="small"
            color="primary"
            disabled={round1Qcount + round2Qcount + round3Qcount === 0}
            onClick={() => props.onClearRounds(row)}
          >
            {Strings.clearRounds}
          </Button>
        );
      })()
    }
  ];
  return {
    cells,
    uniqueKey: row._id
  };
};

export const getWelcomeAvatarRow = (row, props) => {
  const checked = props.selectedRow.includes(row._id);
  const cells = [
    {
      value: (
        <Checkbox
          color="primary"
          onClick={() => props.handleSelect(row)}
          checked={checked}
        />
      )
    },
    {
      value: row.id
    },
    {
      value: (
        <img
          style={{
            width:
              props.path === urlList.buddy
                ? `${Constants.imageRatio.buddyImage[0] / 8}px`
                : `${Constants.imageRatio.avtarImage[0] / 8}px`,
            height:
              props.path === urlList.buddy
                ? `${Constants.imageRatio.buddyImage[1] / 8}px`
                : `${Constants.imageRatio.avtarImage[1] / 8}px`
          }}
          src={row.avatarBuddyImageUrl}
          alt={"thumbnail"}
        />
      )
    },
    {
      value: row.name,
      className: "LineBreak"
    },

    {
      value: row.size
    },
    {
      value: props.path === urlList.subscriptionAvatars ? row.month : row.type
    },
    {
      value: <>{!checked && getEditDeleteButton(row, props)}</>
    }
  ];

  if (props.path === urlList.landAvatars) {
    cells.splice(5, 1, { value: row.landName }, { value: row.milestone });
  }
  return {
    cells,
    uniqueKey: row._id
  };
};

export const getOverviewRow = (row, props) => {
  return {
    cells: [
      {
        value: row["Child User Name"] || "-"
      },
      {
        value: row["Email Id"] || "-"
      },
      {
        value: row["Phone No"] || "-"
      },
      {
        value: row["Current Plan"] || "-"
      },
      {
        value: row.Status
      },
      {
        value: row["Signed up-on Date"] || "-"
      },
      {
        value: row["Plan Activated Date"] || "-"
      },
      {
        value: row["Plan Cancelled Date"] || "-"
      },
      {
        value: row["Plan Expiry Date"] || "-"
      },
      {
        value: row["Auto Renewal"] || "-"
      },
      {
        value: row["Plan Price"] || "-"
      },
      {
        value: row["Discount Applied"] || "-"
      },
      {
        value: row["Total Revenue to Date"] || "-"
      }
    ],
    uniqueKey: row["Email Id"] || "-"
  };
};

export const getStoryStatusRow = (row, props) => {
  let storyStatusMapper = {
    1: Strings.upload,
    2: Strings.encoding,
    3: Strings.successData,
    4: Strings.error
  };
  let storyStatus = storyStatusMapper[row.videoStatus];
  if (!storyStatus) storyStatus = Strings.uploadStarted;
  const cells = [
    {
      value: row.title,
      className: "SpaceRow"
    },
    {
      value: storyStatus,
      className: "SpaceRow"
    },
    {
      value: (
        <>
          {" "}
          <IconButton
            className="RowButton"
            onClick={() => props.handleCancelStatus(row)}
          >
            <Close />
          </IconButton>
        </>
      ),
      className: "SpaceRow"
    }
  ];

  return {
    cells,
    uniqueKey: row._id
  };
};


export const getCountriesRow = (row, props) => {
  const checked = props.selectedRow.includes(row._id);
  const cells = [
    {
      value: row?.countryName
    },
    {
      value: row?.countryCodeName,
    },
    {
      value: <>{!checked && getEditDeleteButton(row, props, true)}</>
    }
  ];
  return {
    cells,
    uniqueKey: row._id
  };
};