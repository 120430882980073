/**
 * Project          : Pathshala
 * Module           : Global actions file of the project
 * Source filename  : actions.js
 * Description      : this file defines actions of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited

import actions from "./actionLedger";

export const setTimer = () => {
  return {
    type: actions.showRestoreTokenSnackbar
  };
};

export const hideSnackBar = () => {
  return {
    type: actions.hideRestoreTokenSnackbar
  };
};

export const showErrorModal = errorMsg => {
  return {
    type: actions.showErrorModal,
    errorMsg
  };
};

export const hideErrorModal = () => {
  return {
    type: actions.hideErrorModal
  };
};

export const showToastMessage = payload => {
  return {
    type: actions.showToastMessage,
    payload
  };
};

export const hideToastMessage = () => {
  return {
    type: actions.hideToastMessage
  };
};
