/**
 * Project          : Pathshala
 * Module           : urlList file of the project
 * Source filename  : urlList.js
 * Description      : the file defines all the urlList used in the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

export const urlList = {
  base: "/adminpanel",
  login: "/login",
  userDetails: "/user-details",
  islandStory: "/island-stories",
  rewardStory: "/reward-stories",
  activity: "/activities",
  overview: "/overview",
  description: "/description",
  sevenDayTrial: "/7-days-trial",
  standardMonthly: "/standard-monthly",
  standardAnnual: "/standard-annual",
  premium: "/premium",
  starterAvatar: "/starter-avatars",
  subscriptionAvatars: "/subscription-avatars",
  landAvatars: "/land-avatars",
  buddy: "/buddies",
  notification: "/notification",
  payment: "/payment",
  versioning: "/versioning",
  countries:"/countries"
};
