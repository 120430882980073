/**
 * Project          : Pathshala
 * Module           : AdminListing Component file
 * Source filename  : AdminListing.js
 * Description      : the file defines the AdminListing component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited
import React from "react";
import { BoxInput } from "../../BoxInput/BoxInput";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Constants, Strings } from "../../../config";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  Error: {
    alignSelf: "flex-start",
    marginLeft: "14px",
    fontSize: "11px",
    color: "red"
  },
  Flex: {
    display: "flex"
  }
}));

export const AdminListing = props => {
  const [value, setValue] = React.useState(props.value || "");
  const [disable, setDefault] = React.useState(true);
  const classes = useStyles();
  const [error, setError] = React.useState("");

  // field changer
  const handleChange = e => {
    // checking for validations
    if (props.name === "name") {
      if (e.value.length <= Constants.charLimits.userDetails.name) {
        setValue(e.value);
      }
    } else if (props.name === "email") {
      if (e.value) {
        let emailValid = Constants.validationRegex.email.test(e.value);
        if (emailValid) {
          setError(false);
        } else {
          setError(true);
        }
      }

      if (e.value.length <= Constants.charLimits.userDetails.email) {
        setValue(e.value);
      }
    } else if (props.name === "password") {
      if (e.value.length <= Constants.charLimits.userDetails.password) {
        setValue(e.value);
      }
    }
  };

  const showActionButton = () => {
    setDefault(false);
    setError(false);
  };
  const showUpdateButton = () => {
    // there is no previous value for password field
    handleChange({
      value: props.value ? props.value : "",
      name: props.name
    });
    setDefault(true);
    setError(false);
  };

  const handleSave = () => {
    props.handleSave(props.id, value, props.name);
    setDefault(true);
    if (props.name === "password") {
      // to clear the password field after saving the new password
      setTimeout(() => {
        setValue("");
      }, 1000);
    }
  };

  // default return
  return (
    <div className={classes.root}>
      <BoxInput
        type={props.name === "email" ? "email" : "text"}
        width="94%"
        name={props.name}
        value={value}
        variant="outlined"
        margin="dense"
        error={!!error}
        onChange={handleChange}
        disabled={disable}
        required
      />
      {disable ? (
        <Button color="primary" onClick={showActionButton}>
          {Strings.update}
        </Button>
      ) : (
        <div className={classes.Flex}>
          <Button color="secondary" onClick={showUpdateButton}>
            {Strings.cancel}
          </Button>
          <Button disabled={!value || error} onClick={handleSave}>
            {Strings.save}
          </Button>
        </div>
      )}
    </div>
  );
};
