/**
 * Project          : Pathshala
 * Module           : SelectBox Component file
 * Source filename  : SelectBox.js
 * Description      : the file defines the SelectBox component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import "./SelectBox.scss";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// used as a dropdown input
export const SelectBox = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: props.conWidth || "100%"
    },
    FormControlRoot: {
      margin: props.margin || theme.spacing(1.5),
      width: props.width || "unset"
    }
  }));
  const classes = useStyles();
  const MenuProps = {
    getContentAnchorEl: null
  };

  let { nameKey, valueKey, conWidth  } = props;
  let propsCopy = { ...props };
  nameKey && delete propsCopy['nameKey'];
  valueKey && delete propsCopy['valueKey'];
  conWidth && delete propsCopy['conWidth'];

  return (
    <div className={[classes.root, props.className].join(" ")}>
      <FormControl classes={{ root: classes.FormControlRoot }}>
        {props.label && (
          <InputLabel>
            {props.label}{" "}
            {props.required ? <span className="Asterisk">{" *"}</span> : null}{" "}
          </InputLabel>
        )}
        <Select
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          MenuProps={MenuProps}
          {...propsCopy}
        >
          {props.options &&
            props.options.length &&
            props.options.map((ms, i) => (
              <MenuItem key={i} value={valueKey ? ms[valueKey] : ms}>
                {nameKey ? ms[nameKey] : ms}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
