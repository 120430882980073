/**
 * Project          : Pathshala
 * Module           : Loader Component file
 * Source filename  : Loader.js
 * Description      : the file defines the Loader component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

// Loader used in application during network request
import React from "react";
import "./Loader.scss";
import { CircularProgress } from "@material-ui/core";

export function Loader(props) {
  const { className, id } = props;
  return (
    <div id={id ? id : ""} className={className ? className : "Loader"}>
      <CircularProgress />
    </div>
  );
}
