/**
 * Project          : Pathshala
 * Module           : LeftManu Component file
 * Source filename  : LeftManu.js
 * Description      : the file defines the LeftManu component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

// left side static manu to navigate in the application
import React, { useState } from "react";
import { menuItems } from "../../config/MenuItem";
import { NavLink } from "react-router-dom";
import "./LeftManu.scss";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemIcon
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  MovieCreation,
  PersonAdd,
  Settings
} from "@material-ui/icons";
import * as Utils from "../../helper/Utils";
import { Strings } from "../../config";

const NameIconMap = {
  [Strings.settings]: <Settings />,
  [Strings.contentManagement]: <MovieCreation />,
  [Strings.subctiptionManagement]: <PersonAdd />
};

let SimpleListItem = props => {
  const { item } = props;
  let rootUser = Utils.getUserDetails().rootUser;
  let markUp = (
    <ListItem button className={item.className ? item.className : ""}>
      {NameIconMap[item.name] && (
        <ListItemIcon>{NameIconMap[item.name]}</ListItemIcon>
      )}
      <div className={[item.class].join(" ")} />
      <ListItemText disableTypography={true} className="MenuItemName">
        {item.name}
      </ListItemText>
    </ListItem>
  );
  if (item.name === Strings.userDetails) {
    if (rootUser) return markUp;
    else return null;
  } else return markUp;
};

const Nav = props => {
  const { item, index } = props;

  return (
    <NavLink
      key={index}
      className={"inActiveLink"}
      activeClassName={"activeLink"}
      to={{
        pathname: item.link,
        state: {
          name: item.name
        }
      }}
      exact={item.link === "/" ? true : false}
    >
      {props.children}
    </NavLink>
  );
};

const renderListItem = menuItemsCopy => {
  return menuItemsCopy.map((item, index) => {
    if (item.children) {
      return <ListItemExpansion item={item} index={index} key={index} />;
    } else {
      return (
        <Nav item={item} key={index}>
          <SimpleListItem item={item} />
        </Nav>
      );
    }
  });
};

let ListItemExpansion = props => {
  const { item, index } = props;
  const [open, changeOpen] = useState(item.open);
  const handleChange = () => changeOpen(op => !op);
  return (
    <React.Fragment key={index}>
      <ListItem
        button
        onClick={handleChange}
        className={item.className ? item.className : ""}
      >
        <div className={[item.class].join(" ")} />
        {NameIconMap[item.name] && (
          <ListItemIcon>{NameIconMap[item.name]}</ListItemIcon>
        )}
        <ListItemText disableTypography={true} className="MenuItemName">
          {item.name}
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense className="PaddingLeft10" component="div">
          {renderListItem(item.children)}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export function LeftMenu() {
  let menuItemsCopy = Utils.deepCopy(menuItems);
  return (
    <div className="LeftMenuWrapper">
      <div className={"LeftMenu"}>
        <div className="MenuItems">
          <List dense>{renderListItem(menuItemsCopy)}</List>
        </div>
      </div>
    </div>
  );
}
