import { useContext, 
    useEffect, 
    useState  
} from 'react';
import { CountryService } from '../../../service';
import { Strings } from '../../../config';
import { NetworkContext } from '../../../config/network/network';
import { getParamsToFetch } from '../_component/CountriesComponent';
import { useRouteMatch } from "react-router-dom";
import { SelectBox } from '../../../component';
import Publish from './Publish'
import { StoreContext } from '../../../config/store/store';

const allowedPublishRoutes = [
    '/7-days-trial',
    '/standard-monthly',
    '/standard-annual',
    '/premium'
]

const Countries = ({ location }) => {

    const { path } = useRouteMatch();
    const [options, setOptions] = useState([]);
    const [countryValue, selectedValue] = useState('');
    const { networkService } = useContext(NetworkContext);
    const { dispatch } = useContext(StoreContext)

    const getCountriesList = () => {
        networkService({
          ...CountryService.getAllCountries,
          params: getParamsToFetch(path, '')
        }).then(response => {
          if (response.status === Strings.success) {
            const data = normalizeCountries(response)
            setOptions(data);
            selectedValue(data[0]?.value)
            dispatch({ type: 'COUNTRYID', countryId: data[0]?.value, location})
          }
        });
      };
    
       useEffect(() => {
        getCountriesList();
        // eslint-disable-next-line
      }, [path]);

      const normalizeCountries = ({ data }) => {
        return data.map((country) => {
            return {
                name: country?.countryName,
                value: country?._id
            }
        })
      }

      const handleCountryChange = e => {
        const countryId = e.target.value;
        selectedValue(countryId)
        dispatch({ type: 'COUNTRYID', countryId: countryId, location})
      }

    return(
        <>
            {
                allowedPublishRoutes.includes(location)
                && options.length > 0 && countryValue && 
                <Publish 
                    countryId={countryValue}
                    location={location}
                />
            }

            { options?.length > 0 && 
              <SelectBox
                    name="countries"
                    placeholder={Strings.selectCountry}
                    width={'200px'}
                    label={Strings.selectCountry}
                    onChange={handleCountryChange}
                    value={countryValue ? countryValue: ''}
                    nameKey="name"
                    valueKey="value"
                    options={options}
                    required
                    style={{ backgroundColor: "rgb(232, 241, 250)" }}
                />
            }
        
        </>
    )
}

export default Countries