/**
 * Project          : Pathshala
 * Module           : asset management file
 * Source filename  : index.js
 * Description      : the file exports all the assets used in the project by components
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import fileUpload from "./fileUpload.svg";
import fileUploadWhite from "./fileUploadWhite.svg";
import AnnualSubIcon from "./AnnualSubIcon.svg";
import MonthlySubIcon from "./MonthlySubIcon.svg";
import PremiumSubIcon from "./PremiumSubIcon.svg";
import TrialUsersIcon from "./TrialUsersIcon.svg";
import filterIcon from "./filterIcon.svg";

export const Images = {
  fileUpload,
  AnnualSubIcon,
  MonthlySubIcon,
  PremiumSubIcon,
  TrialUsersIcon,
  filterIcon,
  fileUploadWhite
};
