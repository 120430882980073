const apiConfig = require("../config/apiConfig.json");

export const AvatarService = {
  getAllAvatar: {
    method: "GET",
    url: apiConfig.avatar.getAllAvatar
  },
  deleteAvatar: {
    method: "DELETE",
    url: apiConfig.avatar.avatarEndPoint
  },
  addAvatar: {
    method: "POST",
    url: apiConfig.avatar.avatarEndPoint
  },
  editAvatar: {
    method: "PUT",
    url: apiConfig.avatar.avatarEndPoint
  },
  getLevels: {
    method: "GET",
    url: apiConfig.islandStory.getLevels
  }
};
