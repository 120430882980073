/**
 * Project          : Pathshala
 * Module           : network file of the project
 * Source filename  : network.js
 * Description      : this file configure all the http requests
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited
import { createContext, useContext } from "react";
import { MasterLayerContext } from "../../Root";
import * as Utils from "../../helper/Utils";
import axios from "axios";
import { Strings } from "../../config";

const NetworkContext = createContext({});

const NetworkProvider = ({ children, online }) => {
  const { MasterLayerFunctions } = useContext(MasterLayerContext);
  var pendingRequests = 0;
  function hideLoader() {
    pendingRequests--;
    if (!pendingRequests) {
      Utils.hideLoadingIndicator();
    }
  }

  /**
   * Create an Axios Client for app requset usage
   */

  const appClient = axios.create({
    baseURL: Utils.getBaseUrl() + "/cms"
  });
  appClient.interceptors.request.use(function (config) {
    let userDetails = Utils.getUserDetails();
    if (userDetails.token) {
      config.headers.common.Authorization =
        `Bearer ${userDetails.token}` || null;
    }
    return config;
  });
  appClient.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  /**
   * Create an Axios Client for general usage
   */
  const generalClient = axios.create();

  /**
   * Request Wrapper with base url set to _baseUrl.
   */
  const appRequest = function (options) {
    if (!options.hideLI) Utils.showLoadingIndicator();
    pendingRequests++;

    if (!online) {
      let errorMessage = {
        title: Strings.connectionLost,
        message: Strings.youAreOffline
      };
      hideLoader();
      MasterLayerFunctions.showAlertMessage(errorMessage);
      return Promise.reject(errorMessage);
    }
    const onSuccess = function (response) {
      hideLoader();
      if (response && response.data) {
        return response.data;
      } else {
        return false;
      }
    };

    const onError = function (error) {
      let errorResult;
      let errorMessage = { title: "", message: {} };
      hideLoader();
      if (error.response) {
        errorResult = error.response;

        // Request was made but server responded with something
        // other than 2xx
        errorMessage.status = errorResult.status;
        if (errorResult.data && errorResult.data.message) {
          errorMessage.title = Strings.oops;
          errorMessage.message = errorResult.data.message;
        } else {
          errorMessage.title = Strings.oops;
          errorMessage.message = Strings.errorMessage;
        }
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        errorResult = error.request;
        let online = window.navigator.onLine;
        if (online) {
          errorMessage.title = Strings.error;
          errorMessage.message = Strings.errorMessage;
        } else {
          errorMessage.title = Strings.error;
          errorMessage.message = Strings.pleaseCheckYourInternet;
        }
      }
      //  else {
      //   // Something happened in setting up the request that triggered an Error
      //   errorResult = error.message;
      //   errorMessage.title = Strings.error;
      //   errorMessage.message = error.message;
      // }
      
if (errorMessage.message && errorMessage.message !== Strings.storyIdAlreadyExists && !options.hideErrorModal ) {
  MasterLayerFunctions.showAlertMessage(errorMessage);
}

      return Promise.reject(errorResult);
    };

    return appClient(options).then(onSuccess).catch(onError);
  };

  /**
   * General api wrpper. No base URL is set
   */
  const generalRequest = function (options) {
    const onSuccess = function (response) {
      console.debug("Request Successful!", response);
      return response.data;
    };

    const onError = function (error) {
      console.log(error);
      console.error("Request Failed:", error.config);

      if (error.response) {
        // Request was made but server responded with something
        // other than 2xx

        console.log("Status:", error.response.status);
        console.log("Data:", error.response.data);
        console.log("Headers:", error.response.headers);
      } else {
        // Something else happened while setting up the request
        // triggered the error
        console.error("Error Message:", error.message);
      }

      return Promise.reject(error.response || error.message);
    };

    return generalClient(options).then(onSuccess).catch(onError);
  };

  const networkService = serviceObj => {
    return appRequest(serviceObj);
  };

  const networkServiceGeneralRequest = serviceObj => {
    return generalRequest(serviceObj);
  };

  return (
    <NetworkContext.Provider
      value={{ networkService, networkServiceGeneralRequest }}
    >
      {children}
    </NetworkContext.Provider>
  );
};

export { NetworkContext, NetworkProvider };
