/**
 * Project          : Pathshala
 * Module           : SummaryBox Component file
 * Source filename  : SummaryBox.js
 * Description      : the file defines the SummaryBox component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";
import { Strings } from "../../config";
import { Images } from "../../assets";

// used to display summary in overview page
export const SummaryBox = props => {
  const { name, number } = props;
  const colorMapper = {
    [Strings.trialUsers]: "#59B05C",
    [Strings.monthlySubscribers]: "#EC4D49",
    [Strings.annualSubscribers]: "#1DBFD4",
    [Strings.premiumSubscibers]: "#FCB70D"
  };
  const iconMapper = {
    [Strings.trialUsers]: <img src={Images.TrialUsersIcon} alt="icon" />,
    [Strings.monthlySubscribers]: (
      <img src={Images.MonthlySubIcon} alt="icon" />
    ),
    [Strings.annualSubscribers]: <img src={Images.AnnualSubIcon} alt="icon" />,
    [Strings.premiumSubscibers]: <img src={Images.PremiumSubIcon} alt="icon" />
  };
  const useStyles = makeStyles(theme => ({
    root: {
      padding: "10px"
    },
    Icon: {
      background: colorMapper[name],
      width: "60px",
      height: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.common.white,
      borderRadius: "3px",
      marginRight: "15px"
    },
    ContentContainer: {
      display: "flex"
    },
    Content: {
      alignSelf: "flex-end",
      marginBottom: "5px"
    },
    Name: {
      fontSize: "11px",
      color: theme.palette.grey[600]
    },
    Number: {
      fontSize: "18px",
      color: colorMapper[name]
    }
  }));
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Box className={classes.ContentContainer}>
        <Box className={classes.Icon}>{iconMapper[name]}</Box>
        <Box className={classes.Content}>
          <div className={classes.Name}>{name}</div>
          <div className={classes.Number}>{number}</div>
        </Box>
      </Box>
    </Paper>
  );
};
