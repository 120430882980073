/**
 * Project          : Pathshala
 * Module           : ConfirmationBox Component file
 * Source filename  : ConfirmationBox.js
 * Description      : the file defines the ConfirmationBox component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  DialogContentText
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import "./ConfirmationBox.scss";
import { Strings } from "../../config";

// used as confirmation dialog for deleting items
export const ConfirmationDialog = props => {
  return (
    <Dialog
      className={"ConfirmationDialog"}
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        <div className={"TitleContainer"}>
          <div className="Title">{props.title}</div>
          <IconButton className="IconButton" onClick={props.handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={props.contentClassName || ""}>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {!props.hideAction && (
          <>
            <Button
              color="primary"
              onClick={props.handleClose}
              variant="outlined"
              size="small"
            >
              {Strings.close}
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={props.handleConfirm}
            >
              {Strings.OK}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
