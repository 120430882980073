/**
 * Project          : Pathshala
 * Module           : PageHeader Component file
 * Source filename  : PageHeader.js
 * Description      : the file defines the PageHeader component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

// header of the application to show page name and logout button.
import { IconButton, AppBar, Toolbar, Typography } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import React from "react";
import "./PageHeader.scss";
import { makeStyles } from "@material-ui/core/styles";
import * as Utils from "../../helper/Utils";
import CountryList from '../../container/Countries/Dropdown';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  iconButton: {
    color: "white"
  }
}));

export function PageHeader(props) {
  const classes = useStyles();

  const onLogout = () => {
    Utils.clearStorage();
    clearTimeout(localStorage.getItem("timerId"));
    localStorage.removeItem("timerId");
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {props.pageName}
          </Typography>
          <CountryList />
          <IconButton className={classes.iconButton} onClick={onLogout}>
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}
