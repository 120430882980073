import React from "react";
import { Box } from "@material-ui/core";
import { Strings } from "../../config";

export const NoDataFound = () => {
  return (
    <div>
      {" "}
      <Box display="flex" height={80} mt="20px">
        <Box m="auto">{Strings.noData}</Box>
      </Box>
    </div>
  );
};
