/**
 * Project          : Pathshala
 * Module           : OverviewService service file
 * Source filename  : OverviewService.js
 * Description      : OverviewService defines all the requests related to Auth service
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

const apiConfig = require("../config/apiConfig.json");

export const OverViewService = {
  getSubSetting: {
    method: "GET",
    url: apiConfig.overview.setting
  },
  saveSetting: {
    method: "PUT",
    url: apiConfig.overview.setting
  },
  getSubData: {
    method: "GET",
    url: apiConfig.overview.getData
  },
  getSubsriptionData: {
    method: "GET",
    url: apiConfig.overview.subData
  },
  saveSubsriptionData: {
    method: "PUT",
    url: apiConfig.overview.subData
  },
  publishSubscription: {
    method: "POST",
    url: apiConfig.overview.publish
  },
  publishDate: {
    method: "GET",
    url: apiConfig.overview.publishDate
  },
  applyDiscount: {
    method: "POST",
    url: apiConfig.overview.applyDiscount
  }
};
