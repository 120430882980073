/**
 * Project          : Pathshala
 * Module           : AthenticatedRoute File
 * Source filename  : AthenticatedRoute.js
 * Description      : describe AthenticatedRoute of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../hoc/Layout/Layout";
import * as Utils from "../helper/Utils";
import { urlList } from "../config";

const ifForceRemount = matchProps => {
  let remount = false;
  if (matchProps.location.state && matchProps.location.state.remountUniqueKey) {
    remount = true;
  }
  return remount;
};

const AuthenticatedRoute = ({
  component: Component,
  props: cProps,
  ...rest
}) => {
  let loggedIn = 0;
  //Check that user permission list array is not empty
  //if its empty, clear local storage, so that user is redirected to sign-in screen
  // if (Utils.getUserPermissionList().length > 0) {
  //check that the token is present
  if (Utils.isUserLoggedIn()) {
    loggedIn = 1;
  }
  // }
  else {
    Utils.clearStorage();
  }

  const getPermission = permission => {
    if (!permission) return true;
    if (permission.rootUser && Utils.getUserDetails().rootUser) return true;
    else return false;
  };

  const renderComponent = (matchProps, rest) => {
    let markup = null;
    if (loggedIn !== 0) {
      if (getPermission(rest.permissions)) {
        markup = (
          <Layout pageName={rest.name}>
            {ifForceRemount(matchProps) ? (
              <Component
                {...matchProps}
                key={matchProps.location.state.remountUniqueKey}
              />
            ) : (
              <Component {...matchProps} />
            )}
          </Layout>
        );
      } else {
        markup = <Redirect to={Utils.permisableRoute()} />;
      }
    } else {
      markup = <Redirect to={urlList.login} />;
    }
    return markup;
  };
  return (
    <Route {...rest} render={matchProps => renderComponent(matchProps, rest)} />
  );
};

export default AuthenticatedRoute;
