/**
 * Project          : Pathshala
 * Module           : Utils File
 * Source filename  : Utils.js
 * Description      : describe Utils of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import { Constants, urlList } from "../config";
const moment = require("moment");

export const showLoadingIndicator = () => {
  document.getElementById("mainLoader").style.display = "flex";
};

export const hideLoadingIndicator = () => {
  document.getElementById("mainLoader").style.display = "none";
};

export const showLoadingIndicator2 = () => {
  document.getElementById("mainLoader2").style.display = "flex";
};

export const hideLoadingIndicator2 = () => {
  document.getElementById("mainLoader2").style.display = "none";
};

export const validateEmail = email => {
  return Constants.validationRegex.email.test(email);
};

export const deepCopy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const fetchNumber = data => {
  return data.match(/\d/g).join("");
};
export const getUserDetails = () => {
  return (
    JSON.parse(window.localStorage.getItem(Constants.localStorageKeyName)) || {}
  );
};

export const getFormattedDateToDisplay = (
  date,
  format = Constants.dateFormat.DATE_MONTH_YEAR_SLASH
) => {
  if (date) return moment(date).format(format).toString();
  else return null;
};

export const setUserDetails = data => {
  window.localStorage.setItem(
    Constants.localStorageKeyName,
    JSON.stringify(data)
  );
};

export const createRTEDefultValue = text => {
  return [
    {
      type: "paragraph",
      children: [{ text: text }]
    }
  ];
};
export const isUserLoggedIn = () => {
  if (window.localStorage.getItem(Constants.localStorageKeyName)) {
    return true;
  }
  return false;
};

export const clearStorage = () => {
  window.localStorage.removeItem(Constants.localStorageKeyName);
};

export const permisableRoute = () => {
  if (isUserLoggedIn()) {
    return urlList.islandStory;
  }
  return urlList.login;
};

export const formatString = (string, ...args) => {
  let formattedString = string.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== "undefined" ? args[number] : match;
  });
  return formattedString;
};

export const ValidateErrorSevenDayTrial = state => {
  let errorsCopy = {
    avatarToBeUnloked: false,
    storyToBeUnloked: false
  };
  const { avatarToBeUnloked, storyToBeUnloked } = state;
  if (!avatarToBeUnloked) {
    errorsCopy.avatarToBeUnloked = true;
  } else if (
    +avatarToBeUnloked < Constants.numberMinMax.avatarToBeUnloked.min ||
    +avatarToBeUnloked > Constants.numberMinMax.avatarToBeUnloked.max
  ) {
    errorsCopy.avatarToBeUnloked = true;
  } else {
    errorsCopy.avatarToBeUnloked = false;
  }
  if (!storyToBeUnloked) {
    errorsCopy.storyToBeUnloked = true;
  } else if (
    +storyToBeUnloked < Constants.numberMinMax.storyToBeUnloked.min ||
    +storyToBeUnloked > Constants.numberMinMax.storyToBeUnloked.max
  ) {
    errorsCopy.storyToBeUnloked = true;
  } else {
    errorsCopy.storyToBeUnloked = false;
  }

  return errorsCopy;
};

export const sum = (a, b) => {
  let a1 = a ? +a : 0;
  let b1 = b ? +b : 0;
  return a1 + b1;
};

export const ValidateErrorStandardSubscription = (state, path, description) => {
  let errorsCopy = {
    oneLineDescription: false,
    description: false,
    avatarToBeUnloked: false,
    storyToBeUnloked: false,
    appStoreId: false,
    playStoreId: false,
    appStorePrice: false,
    playStorePrice: false
  };
  const {
    appStoreId,
    avatarToBeUnloked,
    oneLineDescription,
    playStoreId,
    storyToBeUnloked,
    appStorePrice,
    playStorePrice
  } = state;

  if (!appStorePrice) {
    errorsCopy.appStorePrice = true;
  } else if (
    +appStorePrice < Constants.numberMinMax.appStorePrice.min ||
    +appStorePrice > Constants.numberMinMax.appStorePrice.max
  ) {
    errorsCopy.appStorePrice = true;
  } else {
    errorsCopy.appStorePrice = false;
  }

  if (!playStorePrice) {
    errorsCopy.playStorePrice = true;
  } else if (
    +playStorePrice < Constants.numberMinMax.playStorePrice.min ||
    +playStorePrice > Constants.numberMinMax.playStorePrice.max
  ) {
    errorsCopy.playStorePrice = true;
  } else {
    errorsCopy.playStorePrice = false;
  }
  if (!appStoreId) {
    errorsCopy.appStoreId = true;
  } else {
    errorsCopy.appStoreId = false;
  }
  if (!playStoreId) {
    errorsCopy.playStoreId = true;
  } else {
    errorsCopy.playStoreId = false;
  }
  if (!oneLineDescription) {
    errorsCopy.oneLineDescription = true;
  } else {
    errorsCopy.oneLineDescription = false;
  }

  if (!avatarToBeUnloked) {
    errorsCopy.avatarToBeUnloked = true;
  } else if (
    +avatarToBeUnloked < Constants.numberMinMax.avatarToBeUnloked.min ||
    +avatarToBeUnloked > Constants.numberMinMax.avatarToBeUnloked.max
  ) {
    errorsCopy.avatarToBeUnloked = true;
  } else {
    errorsCopy.avatarToBeUnloked = false;
  }
  if (path !== urlList.premium) {
    if (!storyToBeUnloked) {
      errorsCopy.storyToBeUnloked = true;
    } else if (
      +storyToBeUnloked < Constants.numberMinMax.storyToBeUnloked.min ||
      +storyToBeUnloked > Constants.numberMinMax.storyToBeUnloked.max
    ) {
      errorsCopy.storyToBeUnloked = true;
    } else {
      errorsCopy.storyToBeUnloked = false;
    }
  }
  if (description.every(el => el.text)) {
    errorsCopy.description = false;
  } else {
    errorsCopy.description = true;
  }
  return errorsCopy;
};

export const parseOverviewFilter = (filters, dateFilterType, search) => {
  const obj = {
    status: Constants.statusFilterMapper[filters.status],
    plan: Constants.filterPlanMapper[filters.selectedPlan],
    search: search
  };
  if (dateFilterType) {
    obj[Constants.dateParamsMapper[dateFilterType][0]] =
      getFormattedDateToDisplay(
        filters.fromDate,
        Constants.dateFormat.YEAR_MONTH_DATE_DASHED
      );
    obj[Constants.dateParamsMapper[dateFilterType][1]] =
      getFormattedDateToDisplay(
        filters.toDate,
        Constants.dateFormat.YEAR_MONTH_DATE_DASHED
      );
  }
  for (let i in obj) {
    if (!obj[i]) {
      delete obj[i];
    }
  }
  return obj;
};

export const objectValidator = obj => {
  return Object.values(obj).every(el => {
    if (Array.isArray(el)) return Boolean(el.length);
    else return Boolean(el);
  });
};

export const getBaseUrl = () => {
  let apiUrl = "";
  if (
    process.env.REACT_APP_NODE_ENV === "Development" ||
    process.env.REACT_APP_NODE_ENV === "Staging" ||
    process.env.REACT_APP_NODE_ENV === "UAT" ||
    process.env.REACT_APP_NODE_ENV === "Pre-Release" ||
    process.env.REACT_APP_NODE_ENV === "Production"
  ) {
    apiUrl = window.location.origin;
  } else {
    apiUrl = "http://localhost:3000";
  }
  return apiUrl;
};
