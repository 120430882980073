/**
 * Project          : Pathshala
 * Module           : AuthService service file
 * Source filename  : AuthService.js
 * Description      : AuthService defines all the requests related to Auth service
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

const apiConfig = require("../config/apiConfig.json");

export const AuthService = {
  login: {
    method: "POST",
    url: apiConfig.auth.login,
    hideErrorModal: true
  },
  logout: {
    method: "POST",
    url: apiConfig.auth.logout,
    hideErrorModal: true
  }
};
