/**
 * Project          : Pathshala
 * Module           : Global actionLedger file of the project
 * Source filename  : actionLedger.js
 * Description      : this file defines actionLedger of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited

const actions = {
  showErrorModal: "SHOW ERROR MODAL",
  hideErrorModal: "HIDE ERROR MODAL",
  showToastMessage: "SHOW TOAST MESSAGE",
  hideToastMessage: "HIDE TOAST MESSAGE",
  showRestoreTokenSnackbar: "SHOW SNACKBAR",
  hideRestoreTokenSnackbar: "HIDE SNACKBAR",
  publishId: "PUBLISHID",
  countryId: "COUNTRYID",
  refreshPublish: "REFRESH PUBLISH"

};

export default actions;
