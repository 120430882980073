/**
 * Project          : Pathshala
 * Module           : DatePicker Component file
 * Source filename  : DatePicker.js
 * Description      : the file defines the DatePicker component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";

// used to take date inputs in overview page
export const DatePicker = ({
  value,
  onChange,
  endLabel,
  name,
  label,
  disableFuture,
  disablePast,
  style
}) => {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      alignItems: "center",
      margin: style?.margin || "15px 0 15px 15px"
    },
    Label: {
      marginLeft: "20px",
      marginTop: "15px"
    },
    pickerRoot: {
      width: style?.width || "150px"
    }
  }));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          classes={{ root: classes.pickerRoot }}
          disableToolbar
          autoOk
          variant="inline"
          format="dd/MM/yyyy"
          margin="dense"
          disableFuture={disableFuture}
          disablePast={disablePast}
          label={label}
          allowKeyboardControl={false}
          value={value}
          onChange={e => onChange({ value: e, name })}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
        />
      </MuiPickersUtilsProvider>
      {endLabel && <label className={classes.Label}>{endLabel}</label>}
    </div>
  );
};
