/**
 * Project          : Pathshala
 * Module           : UploadFile Component file
 * Source filename  : UploadFile.js
 * Description      : the file defines the UploadFile component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import "./UploadFile.scss";
import { Images } from "../../assets";
import { makeStyles } from "@material-ui/core";
import { PreviewFile } from "./PreviewFile";

export const UploadFile = props => {
  const useStyles = makeStyles(theme => ({
    Error: {
      fontSize: "11px",
      color: "red",
      marginTop: "5px"
    }
  }));
  const classes = useStyles();
  const acceptableFileLength = props.acceptableFileLength;
  const [error, setErrors] = useState("");
  const { files } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: props.uploadMimeType,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!rejectedFiles.length) {
        setErrors("");
      }
      rejectedFiles.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === "file-invalid-type") {
            setErrors(`Invalid File Type`);
          }
        });
      });

      let obj = acceptedFiles.map(file => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file)
        });
      });

      props.onChange(obj, props.name);
      return obj;
    },
    multiple: acceptableFileLength === 1 ? false : true
  });

  const clearImage = index => {
    let filesCopy = [...files];
    let result = filesCopy.filter((_, i) => i !== index);
    props.onChange(result, props.name);
    setErrors("");
  };

  const clearExcelFile = () => {
    props.onChange([], props.name);
  };

  const { label } = props;

  const shouldShowDropzone = () => {
    if (files && files.length === acceptableFileLength) {
      return false;
    } else {
      return true;
    }
  };

  const onLoadUploadImage = () => {
    if (error) {
      setErrors("");
    }
  };

  return (
    <div className="UploadFile">
      {label && (
        <label className="Label">
          {`${label}`}{" "}
          {props.required ? <span className="Asterisk">{" *"}</span> : null}{" "}
          {props.sizeLabel &&
            `(${props.sizeLabel[0]}px * ${props.sizeLabel[1]}px)`}
        </label>
      )}
      <div className="Container">
        <div>
          <PreviewFile
            {...props}
            clearExcelFile={clearExcelFile}
            onLoadValidation={props.onLoadValidation}
            clearImage={clearImage}
            setErrors={setErrors}
          />
          {shouldShowDropzone() && (
            <section className="FileZone">
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <img
                  className="UploadImageIcon"
                  src={Images.fileUpload}
                  alt="upload"
                  onLoad={onLoadUploadImage}
                />
              </div>
            </section>
          )}
        </div>
        {error && <div className={classes.Error}>{error}</div>}
      </div>
    </div>
  );
};
