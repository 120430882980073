/**
 * Project          : Pathshala
 * Module           : StoryService service file
 * Source filename  : StoryService.js
 * Description      : StoryService defines all the requests related to Story service
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

const apiConfig = require("../config/apiConfig.json");

export const StoryService = {
  getStoryList: {
    method: "GET",
    url: apiConfig.islandStory.getAll
  },
  getRewardList: {
    method: "GET",
    url: apiConfig.islandStory.getRewards
  },
  editStory: {
    method: "PUT",
    url: apiConfig.islandStory.storyEndPoint
  },
  postStory: {
    method: "POST",
    url: apiConfig.islandStory.storyEndPoint
  },
  getDetails: {
    method: "GET",
    url: apiConfig.islandStory.storyEndPoint
  },
  deleteStory: {
    method: "DELETE",
    url: apiConfig.islandStory.storyEndPoint
  },
  getLevels: {
    method: "GET",
    url: apiConfig.islandStory.getLevels
  },
  searchStory: {
    method: "GET",
    url: apiConfig.islandStory.searchStory
  },
  updateSequence: {
    method: "PUT",
    url: apiConfig.islandStory.updateVideoSequence
  },
  storyStatus: {
    method: "GET",
    url: apiConfig.islandStory.storyStatus
  },
  getSasToken: {
    method: "GET",
    url: apiConfig.auth.getSasToken
  },
  resetVideoStatus: {
    method: "PUT",
    url: apiConfig.islandStory.resetVideoStatus
  },
  encodeVideo: {
    method: "POST",
    url: apiConfig.islandStory.encodeVideo
  }
};
