/**
 * Project          : Pathshala
 * Module           : UserService service file
 * Source filename  : UserService.js
 * Description      : UserService defines all the requests related to User service
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

const apiConfig = require("../config/apiConfig.json");

export const UserService = {
  getAdminList: {
    method: "GET",
    url: apiConfig.userDetails.getAdmin
  },
  updateAdmin: {
    url: apiConfig.userDetails.updateAdmin,
    method: "PUT"
  },
  getNotification: {
    method: "GET",
    url: apiConfig.userDetails.notification,
    // params: {
    //   subscriptionKey: "GLOBAL_NOTIFICATION",
    //   subscriptionDetails: true
    // }
  },
  saveNotification: {
    method: "PUT",
    url: apiConfig.userDetails.notification
  }
};
