/**
 * Project          : Pathshala
 * Module           : Constants file of the project
 * Source filename  : Constants.js
 * Description      : the file defines all the Constants used in the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */
import { Strings } from "./Strings";
import { urlList } from "./urlList";

export const Constants = {
  localStorageKeyName: "cmsUserAuth",
  validationRegex: {
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },

  charLimits: {
    userDetails: {
      name: 50,
      email: 60,
      password: 15
    },
    standardSubscription: {
      oneLineDescription: 60,
      description: 400,
      storyToBeUnloked: 1,
      avatarToBeUnloked: 1,
      appStorePrice: 5
    }
  },
  numberMinMax: {
    avatarToBeUnloked: { min: 1, max: 9 },
    storyToBeUnloked: { min: 1, max: 9 },
    appStorePrice: { min: 1, max: 99999 },
    playStorePrice: { min: 1, max: 99999 }
  },
  errorStatus: {
    unauthorized: 401
  },
  fileMimeType: {
    audio: "audio/mpeg",
    video: "video/mp4", // mp4 and mov file
    image: {
      png: "image/png",
      jpagorjpg: "image/jpeg, image/jpg",
      all: "image/*"
    },
    excel:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    json: "application/json"
  },
  richTextEditorDefaultValue: [
    {
      type: "paragraph",
      children: [{ text: "" }]
    }
  ],
  roundMap: {
    round1: 1,
    round2: 2,
    round3: 3
  },
  roundReverseMap: {
    1: "round1",
    2: "round2",
    3: "round3"
  },
  maxOptionLimitRoun2: 4,
  plans: [
    Strings.trialUsers,
    Strings.monthlySubscribers,
    Strings.annualSubscribers,
    Strings.premiumSubscibers
  ],
  plansKeyMapping: {
    [Strings.trialUsers]: "trialUsers",
    [Strings.monthlySubscribers]: "monthlyUsers",
    [Strings.annualSubscribers]: "annualUsers",
    [Strings.premiumSubscibers]: "premiumUsers"
  },
  filterPlans: [
    "",
    Strings.sevenDayTrial,
    Strings.standardMonthly,
    Strings.standardAnnual,
    Strings.premium
  ],
  filterPlanMapper: {
    [Strings.sevenDayTrial]: 1,
    [Strings.standardMonthly]: 2,
    [Strings.standardAnnual]: 3,
    [Strings.premium]: 4
  },
  status: ["", Strings.Active, Strings.Inactive],
  statusFilterMapper: {
    [Strings.Active]: "active",
    [Strings.Inactive]: "inactive"
  },
  dateParamsMapper: {
    [Strings.signUpDate]: ["signedUpFrom", "signedUpTo"],
    [Strings.planActiveDate]: ["planActiveFrom", "planActiveTo"],
    [Strings.planCancelDate]: ["planCancelFrom", "planCancelTo"],
    [Strings.planExpiryDate]: ["planExpiryFrom", "planExpiryTo"]
  },
  imageRatio: {
    islandStory: [713, 950],
    questionImage: [209, 160],
    answerImage: [138, 107],
    round1Image: [640, 480],
    round2Image: [640, 480],
    round3Image: [640, 480],
    avtarImage: [320, 320],
    buddyImage: [360, 420],
    android720p: [1280, 720],
    androidFhd: [2560, 1440],
    ios2x: [1920, 1080],
    ios3x: [2778, 1284]
  },
  imageRatioFactor: 4,
  datePickerType: [
    "",
    Strings.signUpDate,
    Strings.planActiveDate,
    Strings.planCancelDate,
    Strings.planExpiryDate
  ],
  typeMapping: {
    [urlList.sevenDayTrial]: 1,
    [urlList.standardMonthly]: 2,
    [urlList.standardAnnual]: 3,
    [urlList.premium]: 4
  },
  typeSuccessMessageMapping: {
    [urlList.sevenDayTrial]: Strings.sevenDayUpdateSuccessfully,
    [urlList.standardMonthly]: Strings.standardMonthlyUpdateSuccessfully,
    [urlList.standardAnnual]: Strings.standardAnnualUpdateSuccessfully,
    [urlList.premium]: Strings.premiumUpdateSuccessfully
  },
  storyUnlockFrequencyOptions: [
    { name: "Weekly", value: 1 },
    { name: "Monthly", value: 2 }
  ],
  durationOptions: [
    { name: "Normal", value: 2 },
    { name: "Early", value: 1 },
    { name: "Discount", value: 3 }
  ],
  videoStatus: [
    "Status: Upload Started",
    "Status: Uploading",
    "Status: Encoding",
    "Status: Success",
    "Status: Error"
  ],
  currency: {
    peso: "₱"
  },
  dateFormat: {
    DATE_MONTH_YEAR_SLASH: "dd/mm/yyyy",
    YEAR_MONTH_DATE_DASHED: "yyyy-MM-DD",
    DATE_MONTH_YEAR_DASHED: "DD-MMM-YYYY"
  },
  avatarUnlockFrequencyMapping: {
    1: "Weekly",
    2: "Monthly"
  },
  avatarHeader: path => {
    return [
      {
        value: ""
      },
      {
        value: "ID"
      },
      {
        value: ""
      },
      {
        value: "Name",
        className: "Width20"
      },
      {
        value: "Size"
      },
      {
        value: path === urlList.subscriptionAvatars ? "Month" : "Type"
      },
      {
        value: "Action"
      }
    ];
  },
  activityHeader: [
    {
      value: "Story Id",
      className: "Width15"
    },
    {
      value: "Story Name",
      className: "Width20"
    },
    {
      value: "Round 1",
      className: "Width15"
    },
    {
      value: "Round 2",
      className: "Width15"
    },
    {
      value: "Round 3",
      className: "Width15"
    },
    {
      value: "Action",
      className: "Width15"
    }
  ],
  storyheader: [
    {
      value: ""
    },
    {
      value: "S.no"
    },
    {
      value: "Story Id"
    },
    {
      value: ""
    },
    {
      value: "Title",
      className: "Width15"
    },
    {
      value: "Size"
    },
    {
      value: "Duration"
    },
    {
      value: "Synopsis"
    },
    {
      value: "Action"
    }
  ],
  overviewTableHeader: [
    {
      value: "Child User Name",
      className: "Length-100"
    },
    {
      value: "Email Id",
      className: "Length-150"
    },
    {
      value: "Phone No"
    },
    {
      value: "Current Plan",
      className: "Length-75"
    },
    {
      value: "Status",
      className: ""
    },
    {
      value: "Signed up-on date",
      className: "Length-125"
    },
    {
      value: "Plan Activated Date",
      className: "Length-125"
    },
    {
      value: "Plan Cancelled Date",
      className: "Length-125"
    },
    {
      value: "Plan Expiry Date",
      className: "Length-125"
    },
    {
      value: "Auto Renewal",
      className: "Length-125"
    },
    {
      value: "Plan Price",
      className: "Length-125"
    },
    {
      value: "Discount Applied",
      className: "Length-125"
    },
    {
      value: "Total Revenue to Date",
      className: "Length-125"
    }
  ],
  countriesheader: [
    {
      value: "Country Name"
    },
    {
      value: "Country Code"
    },
    {
      value: "Action"
    }
  ],
  timerToShowSnackBar: 1000 * 60 * 55,
  timerToHideSnackBar: 1000 * 60 * 5
};
