import { Badge } from "@material-ui/core";
import { Close, CloudUpload, Description, Memory } from "@material-ui/icons";
import React from "react";
import { Constants, Strings } from "../../config";
import { deepCopy } from "../../helper/Utils";
import "./UploadFile.scss";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
export const PreviewFile = props => {
  const useStyles = makeStyles(theme => ({
    Table: {
      padding: "5px",
      color: theme.palette.grey[400]
    },
    TableData: {
      minWidth: "40px"
    }
  }));
  const classes = useStyles();
  const { files, clearExcelFile, clearImage, setErrors } = props;
  if (
    props.editMode &&
    props.uploadFileType === "excel" &&
    files.length &&
    Array.isArray(files[0])
  ) {
    let list = deepCopy(files[0]);
    let modifiedList = [];
    if (list.length) {
      let j = list.splice(0, 3);
      while (j.length) {
        modifiedList.push(j);
        j = list.splice(0, 3);
      }
    }
    return (
      <div className="PreviewName">
        <Badge
          badgeContent={
            <Close className="CloseIcon" onClick={clearExcelFile} />
          }
          color="secondary"
        >
          <table className={classes.Table}>
            <tbody>
              {modifiedList.map((row, i) => (
                <tr key={i}>
                  {row.map((el, j) => (
                    <td className={classes.TableData} key={j}>
                      {el.word}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Badge>
      </div>
    );
  }
  const onImageLoad = e => {
    if (props.sizeLabel && props.sizeLabel.length) {
      if (
        props.sizeLabel[0] !== e.target.naturalWidth ||
        props.sizeLabel[1] !== e.target.naturalHeight
      ) {
        const currentDimention = `${e.target.naturalWidth}px * ${e.target.naturalHeight}px`;
        const targetDimention = `${props.sizeLabel[0]}px * ${props.sizeLabel[1]}px`;
        setErrors(
          `${Strings.imageDimensionIsNotMatching} (${currentDimention}). ${Strings.itShouldBe} (${targetDimention})`
        );
        props.onLoadValidation(false, props.name);
      } else {
        props.onLoadValidation(true, props.name);
      }
    }
  };
  return (
    files &&
    files.map((file, index) => {
      let showCrossIcon = true;
      let message = null;
      const filePreview = file && file.preview ? file.preview : file;
      let markup;
      let badgedMarkup = null;
      if (props.uploadFileType === "audio") {
        markup = (
          <audio controls className="audio-element">
            <source src={filePreview}></source>
          </audio>
        );
      }
      if (props.uploadFileType === "video") {
        markup = (
          <video controls className="VideoElement">
            <source src={filePreview}></source>
          </video>
        );
        if (
          props.editMode &&
          props.files &&
          props.files.length &&
          typeof props.files[0] === "number"
        ) {
          let messageClasses = ["Message"];
          if (props.files[0] === 4) {
            messageClasses.push("Error");
          }
          message = (
            <div className={messageClasses.join(" ")}>
              {Constants.videoStatus[props.files[0]]}
            </div>
          );
          switch (props.files[0]) {
            case 0:
              markup = <RotateLeftIcon className="ExcelIcon" />;
              break;
            case 1:
              markup = <CloudUpload className="ExcelIcon" />;
              break;
            case 2:
              markup = <Memory className="ExcelIcon" />;
              showCrossIcon = false;
              break;
            case 3:
              markup = <OndemandVideoIcon className="ExcelIcon" />;
              break;
            case 4:
              markup = <ErrorOutlineIcon className="ErrorIcon" />;
              break;
            default:
              markup = <OndemandVideoIcon className="ExcelIcon" />;
          }
        }
      }
      if (props.uploadFileType === "image") {
        let style = {};
        if (props.sizeLabel) {
          style.width =
            props.sizeLabel[0] > 300
              ? `${props.sizeLabel[0] / Constants.imageRatioFactor}px`
              : `${props.sizeLabel[1]}px`;
          style.height =
            props.sizeLabel[1] > 300
              ? `${props.sizeLabel[1] / Constants.imageRatioFactor}px`
              : `${props.sizeLabel[1]}px`;
          if (props.sizeLabel[0] > 2000) {
            style.width = `${props.sizeLabel[0] / 5}px`;
            style.height = `${props.sizeLabel[1] / 5}px`;
          }
        }
        markup = (
          <img style={style} onLoad={onImageLoad} src={filePreview} alt={"x"} />
        );
      }
      if (
        (props.uploadFileType === "excel" && typeof file === "object") ||
        props.uploadFileType === "json"
      ) {
        markup = <Description className="ExcelIcon" />;
      }
      if (markup) {
        badgedMarkup = (
          <div className="PreviewName" key={index}>
            {showCrossIcon ? (
              <Badge
                badgeContent={
                  <Close
                    className="CloseIcon"
                    onClick={() => clearImage(index)}
                  />
                }
                color="secondary"
              >
                {markup}
              </Badge>
            ) : (
              markup
            )}
          </div>
        );
      } else return null;
      if (message) {
        return (
          <div className="IconContainer">
            {badgedMarkup}
            {message}
          </div>
        );
      } else {
        return badgedMarkup;
      }
    })
  );
};
