/**
 * Project          : Pathshala
 * Module           : Strings file of the project
 * Source filename  : Strings.js
 * Description      : the file defines all the Strings used in the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

export const Strings = {
  OK: "Ok",
  enterEmail: "Enter Email",
  settings: "Settings",
  enterPassword: "Enter Password",
  avatarsAndBuddies: "Avatars & Buddies",
  email: "Email",
  description: "Description",
  storyIdAlreadyExists: "Story Id Already Exists!",
  sevenDayTrial: "7 Days Trial",
  password: "Password",
  youAreOffline: "You're offline right now. Check your connection.",
  filter: "Filter",
  export: "Export",
  datefilter: "Date filter",
  datepicker: "Datepicker",
  fromDate: "From Date",
  toDate: "To Date",
  SubscriptionsArePublishedSuccessfully:
    "Subscriptions are published successfully",
  DiscountApplied1: "Discount percentage",
  DiscountApplied2: "Discount on 2nd child (%)",
  searchByChildName: "Search by child name or email Id",
  login: "Login",
  pleaseEnterValidEmail: "Please enter a valid email",
  thisFieldIsRequired: "This field is required",
  emailIdOrPasswordIsNotMatching:
    "Login credentials do not match. Please try again!",
  close: "close",
  success: "success",
  standardMonthly: "Standard Monthly",
  standardAnnual: "Standard Annual",
  premium: "Premium",
  userDetails: "User Details",
  update: "Update",
  discountAppliedSuccessfully: "Discount Applied Successfully",
  applyConfirmationString: "Do you want to apply {0}% discount for {1} child ?",
  save: "Save",
  someErrorOccurred: "Oops! some error occurred while updating the user.",
  islandStory: "Island Stories",
  contentManagement: "Content Management",
  storyVideo: "Story Videos",
  rewardStory: "Reward Stories",
  delete: "Delete",
  storyAddedSuccessfully: "Story Added Successfully",
  storyUpdatedSuccessfully: "Story Updated Successfully",
  round1ActivityDetails: "Round 1 Activity Details",
  round2ActivityDetails: "Round 2 Activity Details",
  round3ActivityDetails: "Round 3 Activity Details",
  edit: "Edit",
  questionSavedSuccessfully: "Question Saved Successfully",
  viewSynopsis: "View Synopsis",
  markAsCurrect: " Mark As Correct",
  addOption: "Add option",
  cancel: "Cancel",
  addStory: "Add Story",
  editStory: "Update Story",
  areYouSureWantToDelete:
    "Are you sure you want to delete the selected stories?",
  areYouSureWantToDeleteSelected:
    'Are you sure you want to delete "{0}" story?',
  storyDeletedMsg: "Story Deleted Successfully",
  oops: "Oops!",
  storyTitle: "Story Title",
  enterStoryTitle: "Enter Story Title",
  storyId: "Story Id",
  enterStoryId: "Enter Story Id",
  selectMilestone: "Select Milestone",
  synopsis: "Synopsis",
  activity: "Activities",
  selectLevel: "Select Level",
  selectStory: "Select Story",
  subctiptionManagement: "Subscription management",
  round1: "Round 1",
  round2: "Round 2",
  round3: "Round 3",
  clearRounds: "Clear rounds",
  enterSynopsis: "Enter Synopsis",
  uploadAudioFile: "Upload Audio",
  uploadCoverImage: "Upload Cover Image",
  uploadWordList: "Upload Word List",
  uploadStory: "Upload Story",
  addRewardStory: "Add Reward Story",
  Active: "Active",
  Inactive: "Inactive",
  editRewardStory: "Update Reward Story",
  addQuestion: "Add Question",
  uploadActivityImage: "Upload Activity Image",
  typeYourStatement: "Type your statement",
  areYouSureYouWantToDeleteOption:
    "Are you sure you want to delete option {0}?",
  areYouSureYouWantToDeleteTheRounds:
    'Are you sure you want to clear the rounds of "{0}"?',
  roundsHaveBeenClearedSuccessfully:
    'All Rounds have been cleared successfully of "{0}"',
  setTimerInSeconds: "Set Timer in seconds",
  pleaseSelectTheDateType: "Please select the date type",
  instructions: "Instructions",
  selectRoundAudio: "Select Round Audio",
  statements: "Statements",
  selectQuestionAudio: "Select Question Audio",
  right: "right",
  wrong: "wrong",
  question: "Question {0}",
  statementDynamic: "Statement {0}",
  chooseDynamic: "Choice {0}",
  optionDynamic: "Option {0}",
  selectOptionImage: "Select Option Image",
  answerStatementDynamic: "Answer Statement {0}",
  areYouSureYouWantToDeleteTheQuestion:
    "Are you sure you want to delete the question?",
  questionDeletedSuccessfully: "Question deleted successfully",
  overview: "Overview",
  trialUsers: "Trial Users",
  monthlySubscribers: "Monthly Subscribers",
  annualSubscribers: "Annual Subscribers",
  premiumSubscibers: "Premium Subscribers",
  coverImageForIos: "Cover image for IOS",
  coverImage: "Cover Image",
  coverImageForAndroid: "Cover image for Android",
  starterAvatars: "Starter Avatars",
  subscriptionAvatars: "Subscription Avatars",
  landAvatars: "Land Avatars",
  buddy: "Buddies",
  addAvatar: "Add Avatar",
  editAvatar: "Update Avatar",
  addBuddy: "Add Buddy",
  editBuddy: "Update Buddy",
  enterName: "Enter Name",
  enterId: "Enter Id",
  selectAvatarType: "Select Avatar Type",
  selectMonth: "Select Month",
  selectLand: "Select Land",
  avatarDeletedMsg: "{0} Deleted Successfully",
  avatarAddedSuccessfully: "{0} Added Successfully",
  avatarUpdatedSuccessfully: "{0} Updated Successfully",
  uploadAvatar: "Upload Avatar",
  uploadBuddy: "Upload Buddy",
  signUpDate: "Signed Up-On Date",
  planActiveDate: "Plan Activated Date",
  planCancelDate: "Plan Cancelled Date",
  planExpiryDate: "Plan Expiry Date",
  happyBuddy: "Happy Animation",
  sadBuddy: "Sad Animation",
  blinkBuddy: "Blink Animation",
  invalidDateRange: "Invalid date range",
  searchPlaceholder: "Search By name, {0} Id",
  round3Info: "Statements 1 through 4 should be entered",
  avatarId: "Avatar",
  buddyId: "Buddy",
  avatarTypeWelcome: "Welcome",
  avatarTypeBonus: "Bonus Avatar",
  avatarType: "Starter Avatar",
  versioning: "Subscription Versioning",
  areYouSureWantToDeleteAvatarBuddy:
    "Are you sure you want to delete the selected {0}?",
  areYouSureWantToDeleteSelectedAvatar:
    'Are you sure you want to delete "{0}" avatar?',
  areYouSureWantToDeleteSelectedBuddy:
    'Are you sure you want to delete "{0}" buddy?',
  avatars: "Avatars",
  noData: "No results found.",
  payment: "Payment",
  plan: "Plan",
  status: "Status",
  apply: "Apply",
  clear: "Clear",
  subDetailsUpdateSuccessfully:
    "Subscription descriptions are updated successfully",
  sevenDayUpdateSuccessfully: "7 days trial details updated successfully",
  standardMonthlyUpdateSuccessfully:
    "Standard monthly details updated successfully",
  standardAnnualUpdateSuccessfully:
    "Standard annual details updated successfully",
  premiumUpdateSuccessfully: "Premium details updated successfully",
  welcomeScreenDescription: "Welcome Screen Description",
  subscribeNowText: "Subscribe Now Text",
  freeTrialText: "Free Trial Text",
  subscriptionScreenDescription: "Subscription Screen Description",
  overallDescription: "Overall Description",
  android: "Android",
  ios: "iOS",
  promotionalScreenAndroid720p: "Promotional Screen Android 720p",
  promotionalScreenAndroidFHD: "Promotional Screen Android FHD",
  promotionalScreenIOS2x: "Promotional Screen IOS 2x",
  promotionalScreenIOS3x: "Promotional Screen IOS 3x",
  NumberOfActiveUsers: "Number of active users:",
  oneLineDescription: "One Line Description",
  briefDescription: "Brief Description",
  YourSessonIsAboutToEnd: "Your session is about to end !",
  restoreToken: "Restore Session",
  accessibility: "Accessibility",
  numberOfStoryToBeUnlocked: "Number of story to be unlocked",
  numberOfAvatarToBeUnlocked: "Number of avatar to be unlocked",
  DisplayDiscountLabelFor2ndChild: "Display discount label for 2nd child",
  OverrideOverallDescription: "Override Overall Description",
  activeUsers: "Active users:",
  storyUnlockFrequency: "Story unlock frequency",
  avatarUnlockFrequency: "Avatar unlock frequency :",
  minimumSupportedVersion: "Minimum supported version",
  latestAppVersion: "Latest App version",
  DoYouWantToUpdateThePricesForAllDuration:
    "Do you want to update the prices for all durations?",
  PricesUpdatedSuccessfully: "Prices updated successfully",
  child: "Child",
  appStoreID: "App Store ID",
  playStoreID: "Play Store ID",
  appStorePrice: "App Store Price",
  playStorePrice: "Play Store Price",
  notification: "Notifications",
  notificationReminder: "Notification Reminder",
  notificationUpdatedSuccessfully: "Notification setting saved successfully",
  imageDimensionIsNotMatching: "Selected image dimension is not matching",
  storyStatus: "Story Status",
  error: "Error",
  hideStatus: "Hide Status",
  showStatus: "Show Status",
  connectionLost: "Connection Lost",
  upload: "Uploading",
  uploadStarted: "Upload started",
  lastPublishedDate: "Last published on {0}",
  doYouWantToDiscard: "Do you want to discard the changes you have done?",
  doYouWantToPublish:
    "Do you want to publish the changes you have done for all the subscription pages?",
  encoding: "Encoding",
  successData: "Success",
  normalBuddy: "Normal Animation",
  treasureBoxBuddy: "Treasurebox Animation",
  rockyPathBuddy: "Rocky Path Animation",
  bridgeBuddy: "Bridge Animation",
  mysteryRevealBuddy: "Mystery Reveal Animation",
  unknown: "Unknown",
  itShouldBe: "It should be",
  invalidInput: "Invalid input",
  publish: "publish",
  addDescription: "Add Description",
  tokenGenerationError: "Something went wrong! Please try to login again.",
  countries: "Countries",
  addCountry: "Add Country",
  countryName: "Enter Name",
  countryCode: "Country Code",
  editCountry: "Update Country",
  countryId: "Country",
  countryDeletedMsg: "{0} Deleted Successfully",
  areYouSureWantToDeleteSelectedCountry:
  'Are you sure you want to delete "{0}" Country?',
  countryAddedSuccessfully: "{0} Added Successfully",
  countryUpdatedSuccessfully: "{0} Updated Successfully",
  selectCountry: "Select Country",
  selectPublishId: "Publish ID",
};
