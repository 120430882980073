import Countries from './Countries'
import { useLocation } from 'react-router-dom'
import { Box } from "@material-ui/core";
import './Dropdown.scss'

const allowedCountryRoutes = [
    '/overview',
    '/description', 
    '/7-days-trial',
    '/standard-monthly',
    '/standard-annual',
    '/premium'
]

const CountryList = () => {

    const location = useLocation();
    const pathname = location?.pathname || ''
    console.log('pathname', pathname)

    return(
        <Box display="flex" flexGrow={2} className="countryPublish">
            { 
                allowedCountryRoutes.includes(pathname) 
                && <Countries location={pathname}/> 
            }
        </Box>
    )

}

export default CountryList