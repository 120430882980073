/**
 * Project          : Pathshala
 * Module           : Global store file of the project
 * Source filename  : store.js
 * Description      : this file defines store of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited

import { createContext, useReducer } from "react";
import { reducer } from "./reducer";

const initState = {
  errorMsg: null,
  toastMsg: null,
  showRestoreTokenPopup: false,
  publishCountryId: {
    publishId: 0,
    countryId: '',
    location: ''
  },
  refreshPublish: false
};

const StoreContext = createContext({});

const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(reducer, { ...initState });
  return (
    <StoreContext.Provider value={{ store, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
