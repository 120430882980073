import React from "react";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// used as a list where items positions can be drag and drop
// use case question list in activity
export const DragableList = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      "& .MuiList-padding": {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={props.handleDropEnd}>
        <Droppable droppableId={"listdnd"}>
          {provided => (
            <List
              component="ul"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.items.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {provide => (
                    <ListItem
                      {...provide.dragHandleProps}
                      {...provide.draggableProps}
                      ref={provide.innerRef}
                      onClick={() => props.onSelectQuestion(item)}
                      className={
                        props.selectedQuestion === item._id
                          ? "ListItemActive"
                          : ""
                      }
                    >
                      <ListItemText primary={item.text} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
