/**
 * Project          : Pathshala
 * Module           : ActivityService service file
 * Source filename  : ActivityService.js
 * Description      : ActivityService defines all the requests related to Auth service
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

const apiConfig = require("../config/apiConfig.json");

export const ActivityService = {
  getQuestions: {
    method: "GET",
    url: apiConfig.activity.getQuestions
  },
  getQuestionDetails: {
    method: "GET",
    url: apiConfig.activity.questionEndPoint
  },
  addQuestion: {
    method: "POST",
    url: apiConfig.activity.questionEndPoint
  },
  saveQuestion: {
    method: "PUT",
    url: apiConfig.activity.questionEndPoint
  },
  deleteQuestion: {
    method: "DELETE",
    url: apiConfig.activity.questionEndPoint
  },
  updateQuestionSequence: {
    method: "PUT",
    url: apiConfig.activity.questionSequence
  },
  clearRounds: {
    method: "DELETE",
    url: apiConfig.activity.clearRounds
  }
};
