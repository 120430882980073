/**
 * Project          : Pathshala
 * Module           : BoxInput Component file
 * Source filename  : TextEditor.js
 * Description      : the file defines TextEditor component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React, { useMemo, useState, useCallback, useEffect } from "react";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import { Button, ButtonGroup, makeStyles, Paper } from "@material-ui/core";
import { toggleMark, SlateElement, SlateLeaf } from "./SlateEditorUtils";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import { FormatUnderlined } from "@material-ui/icons";
import { deepCopy } from "../../helper/Utils";

const buttonsArray = [
  {
    format: "bold",
    active: false
  }
  // {
  //   format: "italic",
  //   active: false
  // },
  // {
  //   format: "underline",
  //   active: false
  // }
];

export const TextEditor = ({
  value,
  onChange,
  className,
  name,
  editorClassName,
  containerWidth,
  placeholder,
  maxLimit,
  required,
  editorError,
  setEditorError
}) => {
  const useStyles = makeStyles(theme => ({
    Editor: {
      backgroundColor: "white",
      padding: "0px 10px",
      border: "1px solid",
      borderColor: theme.palette.grey[400],
      borderRadius: "5px"
    },
    paper: {
      paddingLeft: 0,
      paddingTop: "4px"
    },
    root: {
      width: containerWidth || "100%"
    },
    error: {
      padding: "5px 0px",
      margin: 0,
      color: "red",
      fontSize: "11px"
    }
  }));
  const editor = useMemo(() => withReact(createEditor()), []);
  const [buttons, setButtons] = useState(deepCopy(buttonsArray));

  const IconMapper = {
    bold: <FormatBoldIcon />,
    italic: <FormatItalicIcon />,
    underline: <FormatUnderlined />
  };

  useEffect(() => {
    checkValid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const checkValid = () => {
    if (maxLimit && setEditorError) {
      let currentLength = 0;
      if (value && value[0] && value[0].children && value[0].children.length) {
        value[0].children.forEach(el => {
          currentLength += el.text.length;
        });
        if (currentLength > maxLimit) {
          setEditorError(true);
        } else {
          setEditorError(false);
        }
      }
    }
  };

  const renderElement = useCallback(props => <SlateElement {...props} />, []);
  const renderLeaf = useCallback(props => <SlateLeaf {...props} />, []);
  const classes = useStyles();
  const placeholderC = `${placeholder} ${required ? "*" : ""}`;
  return (
    <div className={[classes.root, className].join(" ")}>
      <Slate
        editor={editor}
        value={value}
        onChange={v => {
          onChange({ value: v, name });
        }}
      >
        <Editable
          placeholder={placeholderC}
          className={[classes.Editor, editorClassName].join(" ")}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
        />
      </Slate>
      {maxLimit && editorError && (
        <p className={classes.error}>
          Maximum of {maxLimit} characters are allowed
        </p>
      )}
      <Paper elevation={0} className={classes.paper}>
        <ButtonGroup size="small">
          {buttons.map((item, i) => (
            <Button
              key={i}
              onMouseDown={event => {
                event.preventDefault();
                let isActive = toggleMark(editor, item.format);
                let items = Array.from(buttons);
                items[i].active = isActive;
                setButtons(items);
              }}
              color={item.active ? "primary" : "default"}
            >
              {IconMapper[item.format]}
            </Button>
          ))}
        </ButtonGroup>
      </Paper>
    </div>
  );
};
