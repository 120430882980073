/**
 * Project          : Pathshala
 * Module           : MenuItem file of the project
 * Source filename  : MenuItem.js
 * Description      : the file defines all the MenuItem used in the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */
import { Strings } from "./Strings";
import { urlList } from "./urlList";

export const menuItems = [
  {
    name: Strings.contentManagement,
    className: "List1",
    open: true,
    children: [
      {
        name: Strings.storyVideo,
        className: "List2",
        open: true,
        children: [
          {
            name: Strings.islandStory,
            link: urlList.islandStory
          },
          {
            name: Strings.rewardStory,
            link: urlList.rewardStory
          }
        ]
      },
      {
        name: Strings.activity,
        link: urlList.activity
      },
      {
        name: Strings.avatarsAndBuddies,
        link: "/avatar-and-buddy",
        className: "List2",

        children: [
          {
            name: Strings.starterAvatars,
            link: urlList.starterAvatar
          },
          {
            name: Strings.subscriptionAvatars,
            link: urlList.subscriptionAvatars
          },
          {
            name: Strings.landAvatars,
            link: urlList.landAvatars
          },
          {
            name: Strings.buddy,
            link: urlList.buddy
          }
        ]
      }
    ]
  },
  {
    link: "/subscription-management",
    name: Strings.subctiptionManagement,
    className: "List1",
    open: false,

    children: [
      {
        name: Strings.overview,
        link: urlList.overview
      },
      {
        name: Strings.description,
        link: urlList.description
      },
      // {
      //   name: Strings.versioning,
      //   link: urlList.versioning
      // },
      {
        name: Strings.sevenDayTrial,
        link: urlList.sevenDayTrial
      },
      {
        name: Strings.standardMonthly,
        link: urlList.standardMonthly
      },
      {
        name: Strings.standardAnnual,
        link: urlList.standardAnnual
      },
      {
        name: Strings.premium,
        link: urlList.premium
      }
    ]
  },
  {
    link: "/settings",
    name: Strings.settings,
    className: "List1",
    open: false,
    children: [
      {
        name: Strings.userDetails,
        link: urlList.userDetails,
        rootUser: true
      },
      {
        name: Strings.countries,
        link: urlList.countries
      },
      {
        name: Strings.notification,
        link: urlList.notification
      }
    ]
  }
];
