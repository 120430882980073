/**
 * Project          : Pathshala
 * Module           : Row Component file
 * Source filename  : Row.js
 * Description      : the file defines the Row component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const Row = props => {
  const { cells, item } = props;
  let dndProps = {};

  if (props.dnd) {
    dndProps = {
      ref: props.reference,
      ...props.draggableProps,
      ...props.dragHandleProps
    };
  }

  return (
    <TableRow className={"TableRow"} {...dndProps}>
      {cells && cells.map((cell, index) => {
        return cell.key ? (
          <TableCell
            key={index}
            className={cell.className}
            onClick={() => {
              props.onClick(cell.key, item);
            }}
            id={cell.id}
            style={cell.style}
            align={cell.align || "inherit"}
          >
            {cell.value}
          </TableCell>
        ) : (
          <TableCell
            className={cell.className}
            key={index}
            id={cell.id}
            style={cell.style}
            align={cell.align || "inherit"}
          >
            {cell.value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default Row;
