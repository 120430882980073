/**
 * Project          : Pathshala
 * Module           : Global reducer file of the project
 * Source filename  : reducer.js
 * Description      : this file defines reducer of the project
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

//Copyright © 2021, Learning Possibilities Limited

import actions from "./actionLedger";

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.showErrorModal:
      return {
        ...state,
        errorMsg: action.errorMsg
      };

    case actions.hideErrorModal:
      return {
        ...state,
        errorMsg: null
      };

    case actions.showToastMessage:
      return {
        ...state,
        toastMsg: action.payload
      };
    case actions.hideToastMessage:
      return {
        ...state,
        toastMsg: null
      };

    case actions.showRestoreTokenSnackbar:
      return {
        ...state,
        showRestoreTokenPopup: true
      };
    case actions.hideRestoreTokenSnackbar:
      return {
        ...state,
        showRestoreTokenPopup: false
      };
    case actions.publishId:
      return {
        ...state,
        publishCountryId: { 
          ...state.publishCountryId,
          publishId: action.publishId
        }
      }
    case actions.countryId:
      return {
        ...state,
        publishCountryId: { 
          ...state.publishCountryId,
          countryId: action.countryId,
          location: action.location,
          publishId: 0
        }
      }
    case actions.refreshPublish:
        return {
          ...state,
          refreshPublish: action.refreshPublish
        };
    default:
      return state;
  }
};
