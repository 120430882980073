/**
 * Project          : Pathshala
 * Module           : ErrorModal Component file
 * Source filename  : ErrorModal.js
 * Description      : the file defines the ErrorModal component
 * Author           : Debabrata Pal
 * Copyright        : Copyright © 2021, Learning Possibilities Limited
 *                    Written under contract by Robosoft Technologies Pvt. Ltd.
 */

// error dialog
// use case: to show error messages of network request
import React from "react";
import "./ErrorModal.scss";
import { Strings } from "../../config";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";

export const ErrorModal = props => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={Boolean(props.open)}
      disableAutoFocus
    >
      <DialogTitle>
        <div className="TitleContainer">
          <div className="Title">{props.errorMsg.title}</div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{props.errorMsg.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={props.onClose}>
          {Strings.OK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
